import React from 'react';

import { generatePath } from 'react-router-dom';

import { TableGridAsync, LayoutController, LayoutBase, LayoutHeader } from '@asg/core';
import routersUrl from 'configs/routes-static';
import { api } from 'modules/user-administration/api/endpoints';
import { isActiveStatus } from 'utils/dictionaries/administration';

const optionsIsActiveStatus = Object.entries(isActiveStatus).map(([value, label]) => ({ label, value }));

const UsersList: React.FC = () => {
  return (
    <LayoutBase>
      <LayoutHeader
        title="Пользователи"
        hideBackLink
      />

      <LayoutController
        buttons={[
          {
            label: 'Добавить пользователя',
            color: 'orange',

            href: routersUrl.cabinet.users.create.url,
          },
        ]}
      />

      <TableGridAsync
        numerable
        type="standard"
        endpoint={api.userListGrid}
        name={api.userListGrid}
        getLink={(user) => generatePath(routersUrl.cabinet.users.user.card.url, { id: user.id })}
        headers={[
          {
            title: 'ФИО Пользователя',
            value: 'fullName',
            hasFilter: true,
            sortable: true,
          },

          {
            title: 'Роль',
            value: 'role.name',
            render: (value, user: any) =>
              user.role?.isDeleted ? `${value as string} (удалена)` : user?.role?.title || '',
            filter: {
              type: 'simple',
              path: 'roleTitle',
            },
            sortable: true,
          },
          {
            title: 'Подразделение',
            value: 'name',
            filter: {
              type: 'simple',
              path: 'divisionTitle',
            },
            sortable: true,
            render: (value, user) => user?.division?.title || '',
          },

          {
            title: 'Статус',
            value: 'isActive',
            filter: {
              type: 'selector',
              selectFilter: {
                options: optionsIsActiveStatus,
              },
            },
            sortable: true,
            render: (value) => isActiveStatus[value],
          },
          {
            title: 'id',
            value: 'id',
            filter: {
              type: 'simple',
            },
            sortable: true,
          },
        ]}
      />
    </LayoutBase>
  );
};

export default UsersList;
