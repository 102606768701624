import { LOSS_STATUSES } from 'types/loss';
import { PAYMENT_STATUSES } from 'types/payments';

export * from './common';

export const payoffType: Record<string, string> = {
  CASH: 'Наличные',
  NOT_CASH: 'Безналичные',
  POSTAL_TRANSFER: 'Почтовый перевод',
};

export const acceptedTypes: Record<string, string> = {
  APPROVED: 'Согласовано',
  DENIED: 'Не согласовано',
};

export const periods: Record<string, string> = {
  DAY: 'День',
  MONTH: 'Месяц',
  YEAR: 'Год',
  NO_DATA: '-',
};
export const periodsTitle: Record<string, string> = {
  DAY: 'Срок действия, дн.',
  MONTH: 'Срок действия, мес.',
  YEAR: 'Срок действия, лет',
  NO_DATA: 'Срок действия',
};

export const periodsShortVariants: Record<string, string> = {
  DAY: 'дн.',
  MONTH: 'мес.',
  YEAR: 'лет',
  NO_DATA: ' ',
};

export const periodsForTariffsProduct: Record<string, string> = {
  //DAY: 'День',
  //WEEK: 'Неделя',
  //MONTH: 'Месяц',
  //YEAR: 'Год',
  //POLICY_PERIOD: 'На весь перио действия полиса',
  WHOLE_POLICY_PERIOD: 'До окончания действия полиса',
  POLICY_PERIOD_UNIT: 'На единицу срока действия полиса',
};

// Характер события
export const eventTypes = {
  1: 'ДТП',
  2: 'Болезнь',
  3: 'НС',
  4: 'Травма бытовая',
  5: 'Травма производственная',
  6: 'Травма ДТП',
  7: 'Травма криминальная',
  8: 'Иная травма',
  9: 'Заболевание ССС (сердечно-сосудистая система)',
  10: 'Заболевание ПС (пищеварительная система)',
  11: 'Заболевания ДС (дыхательная система)',
  12: 'Заболевания НС (нервная система)',
  13: 'Заболевание ЭС (эндокринная система)',
  14: 'Заболевание ОДА (опорно-двигательный аппарат)',
  15: 'Заболевание МПС (мочеполовая система)',
  16: 'Иное заболевание',
  17: 'Рак ПС (пищеварительная система)',
  18: 'Рак ДС (дыхательная система)',
  19: 'Рак НС (нервная система)',
  20: 'Рак ЭС (эндокринная система)',
  21: 'РАК МПС (мочеполовая система)',
  22: 'Рак кожи',
  23: 'Рак груди',
  24: 'Рак матки',
  25: 'Рак (лейкома)',
  26: 'Рак (лейкоз)',
  27: 'Иное ЗНО',
  28: 'Консультация',
  29: 'Диагностика',
  30: 'Экспертное мнение',
  31: 'Лечение',
  32: 'СПИД',
  33: 'п.1 ч.1 ст. 81',
  34: 'п.2 ч.1 ст. 81',
  35: 'п.10 ст. 77',
  36: 'Иная статья',
  37: 'Дожитие',
  38: 'Нет данных',
};

export const documentTypes = {
  asg: 'АСЖ убытки АСЖ',
  dms: 'АСЖ убытки ДМС',
  oao: 'АСЖ убытки ОАО',
  isg: 'АСЖ убытки ИСЖ',
  prAsg: 'АСЖ убытки ПР АСЖ',
  prAo: 'АСЖ убытки ПР АО',
  documents: 'Документы по убыткам',
};

export const structureTypes = {
  asg: 'АСЖ',
  ao: 'АО',
  isg: 'ИСЖ',
  nsg: 'НСЖ',
  none: 'Не определена',
  branch: 'Филиал',
};

export const structureTypesInLosses: {
  [key: string]: string;
} = {
  ASZH: 'АСЖ',
  AS: 'АС',
  NSZH: 'НСЖ',
  ISZH: 'ИСЖ',
  BRANCH: 'Филиал',
  NOT_SPECIFIED: 'Не определена',
};

export const registryRowStatuses: {
  [key: string]: string;
} = {
  READY_TO_CREATE: 'Готов к созданию',
  NEED_APPROVAL: 'Необходимо согласование',
  ERROR: 'Ошибка, создание невозможно',
  APPROVAL_IN_PROCESS: 'На согласовании',
  CREATED: 'Создан',
  REJECTED: 'Не согласован',
};

export const addendumStatuses: {
  [key: string]: string;
} = {
  CHANGE_INSURED_DATA: 'Изменение персональных данных',
  DATA_CHANGED: 'Данные изменены',
  EDIT: 'Изменение персональных данных',
};

export const registryStatuses: {
  [key: string]: string;
} = {
  NEW: 'Новый',
  IN_PROCESS: 'В работе',
  PROCESSED: 'Обработан',
  PROCESSED_HAS_DEFECT: 'Обработан с ошибками',
  RETURNED: 'Возврат',
  PAID: 'Оплачен',
  REJECTED: 'Отклонен',
  NONE: 'нет данных',
};

export const regestryStatuses: {
  [key: string]: string;
} = {
  NEW: 'Новый',
  IN_PROCESS: 'В работе',
  PROCESSED: 'Обработан',
  PROCESSED_HAS_DEFECT: 'Обработан с ошибками',
  PAID: 'Оплачен',
  REJECTED: 'Отклонен',
};

export const rkStatuses: {
  [key: string]: string;
} = {
  IN_PROCESS: 'В работе',
  PROCESSED: 'Обработан',
  RETURNED: 'Возврат',
};

export const registryStatusesColor: {
  [key: string]: string;
} = {
  NEW: 'grey',
  IN_PROCESS: 'yellow',
  PROCESSED_HAS_DEFECT: 'yellow',
  PROCESSED: 'yellow',
  PAID: 'green',
  RETURNED: 'red',
  REJECTED: 'red',
  NONE: 'red',
};

export const reservedSystems: Record<string, string> = {
  NEW: 'AlfaConnect',
  NIS: 'AlfaConnect',
  INSIS: 'ИНСИС',
  FREE: 'Не определена',
};

export const payemntLinigStatuses: {
  [key: string]: string;
} = {
  FREE: 'Не привязан',
  LINKED_TO_POLICY: 'Привязан к полису',
  LINKED_TO_POLICY_REGISTRY: 'Разнесен на реестр',
};

export const payemntSpreadingStatus: {
  [key: string]: string;
} = {
  SPREAD: 'Разнесен полностью',
  NOT_SPREAD: 'Не разнесен',
  SPREAD_PARTLY: 'Разнесен частично',
};
// Тип события
export const eventsType = {
  1: 'Смерть',
  2: 'Инвалидность I гр.',
  3: 'Инвалидность II гр.',
  4: 'Инвалидность III гр.',
  5: 'СОЗ',
  6: 'ВУТ',
  7: 'Травма',
  8: 'Лечение',
  9: 'Диагностика',
  10: 'Потеря работы',
  11: 'Иное',
};

export const documentTypesRegCard: {
  [key: string]: string;
} = {
  ASZH_LOSS_ASZH_DOCUMENT: 'АСЖ убытки АСЖ',
  ASZH_LOSS_DMS_DOCUMENT: 'АСЖ убытки ДМС',
  ASZH_LOSS_OAO_DOCUMENT: 'АСЖ убытки ОАО',
  ASZH_LOSS_ISZH_DOCUMENT: 'АСЖ убытки ИСЖ',
  // ASZH_LOSS_PRASZH_DOCUMENT: 'АСЖ убытки ПР АСЖ',
  // ASZH_LOSS_PRAO_DOCUMENT: 'АСЖ убытки ПР АО',
  // ASZH_LOSS_DOCS_DOCUMENT: 'Документы по убыткам',
  ASZH_LOSS_PR_ASZH_DOCUMENT: 'АСЖ убытки ПР АСЖ',
  ASZH_LOSS_PR_AO_DOCUMENT: 'АСЖ убытки ПР АО',
  LOSS_DOCUMENT: 'Документы по убыткам',
};

export const lossStatuses: {
  [key: string]: string;
} = {
  NOTIFICATION: 'Уведомление',
  CANCELED: 'Аннулирован',
  IN_PROCESS: 'В работе',
  DENIED: 'Отказ',
  SENT_TO_PAYOFF: 'Отправлен на выплату',
  PAID: 'Оплачен',
  NONE: '',
};

export const approvalStatuses: {
  [key: string]: string;
} = {
  IN_PROCESS: 'В работе',
  WAS_NOT_SENT_TO_PARTNER: 'Не направлялся партнеру',
  WRONG_TARIFF: 'Некорректный тариф',
  WRONG_TAX: 'Некорректный размер НДС',
  ERROR: 'Ошибка',
  APPROVED: 'Ошибок нет',
};

export const reportStatuses: {
  [key: string]: string;
} = {
  NEW: 'Новый',
  IN_PROCESS: 'В работе',
  PROCESSED: 'Обработан',
  RETURNED: 'Возврат',
  REJECTED: 'Отклонен',
  ERROR: 'Ошибка',
  NONE: 'Нет данных',
};

export const terminationTypes: {
  [key: string]: string;
} = {
  CLOSE_BY_COURT: 'Расторжение по решению суда',
  CANCEL_BY_COURT: 'Отмена по решению суда',
  CANCEL_BY_AGENT_REQUEST: 'Отмена по запросу агента',
  CANCEL_BY_CLIENT_REQUEST: 'Отмена по запросу клиента',
};

export const ContactTypes = {
  EMAIL: 'Электронный адрес',
  MOBILE_PHONE: 'Мобильный телефон',
  CITY_PHONE: 'Городской телефон',
  WORK_PHONE: 'Рабочий телефон',
} as const;

export const GenderTypes: {
  [key: string]: string;
} = {
  FEMALE: 'Женский',
  MALE: 'Мужской',
  NON: ' ',
};

export const LawSubjectTypes = {
  INDIVIDUAL: 'Физическое лицо',
  LEGAL: 'Юридическое лицо',
  NO_DATA: '',
} as const;

export const policyStatuses: {
  [key: string]: string;
} = {
  DRAFT: 'Черновик',
  ACTIVE: 'Действует',
  CLOSED: 'Истек срок действия',
  TERMINATED: 'Расторгнут',
  CANCELED: 'Отменен',
  CREATED: 'Создан',
};
export const coverStatus: Record<string, string> = {
  REGISTERED: 'Зарегистриировано',
  PAID: 'Оплачено',
  CLOSED: 'Истек срок действия',
  CANCELED: 'Отменено',
  TERMINATED: 'Расторгнуто',
};

export const policyPayoffCalculateType: Record<string, string> = {
  FULL_PREMIUM: 'Полная премия',
  PRO_RATA: 'Выплата с учетом Про Рата',
  NO_DATA: '',
};

export const policySubStatusesColor: {
  [key: string]: string;
} = {
  PAID: 'green',
  COOLOFF: 'red',
  BY_COURT: 'red',
  EXPIRED: 'red',
  INSURANCE_CASE: 'red',
  CLIENT_REQUEST: 'red',
  PDP: 'red',
  AGENT_REQUEST: 'red',
  NONCONFORMITY: 'red',
  NONE: 'green',
  USER_MISTAKE: 'red',
  NOT_PAID: 'red',
};

export const policyStatusesColor: {
  [key: string]: string;
} = {
  DRAFT: 'grey',
  ACTIVE: 'green',
  CLOSED: 'red',
  CANCELED: 'red',
  CREATED: 'green',
};

export const configStatus: Record<string, string> = {
  DRAFT: 'Черновик',
  ACTIVE: 'Активный',
  CLOSED: 'Закрыт',
};

export const sendingTypes: {
  [key: string]: string;
} = {
  ['BY_RUSSIAN_POST']: 'Почтой РФ, бумажное письмо',
  ['BY_RUSSIAN_POST_EMAIL']: 'Почтой РФ, электронное письмо',
  ['HAND_OVER_PERSONALLY']: 'Выдать на руки',
  ['BY_EMAIL']: 'На электронную почту клиенту',
};

export const requestTypes: {
  [key: string]: string;
} = {
  ['REQUEST']: 'Запрос',
  ['CLAIM_ANSWER']: 'Ответ на претензию',
  ['NONE']: ' ',
};

export const requestTableTypes: {
  [key: string]: string;
} = {
  ['REJECT']: 'Отказ',
  ['REQUEST']: 'Запрос',
  ['CLAIM_ANSWER']: 'Ответ на претензию',
  ['NONE']: ' ',
};

export const requestStatuses: Record<string, string> = {
  IN_PROCESS: 'В работе',
  NONE: ' ',
  DRAFT: 'Черновик',
  FORMED: 'Сформирован',
  SENDING_IN_PROCESS: 'Подготовка к отправке',
  COMPLETED: 'Завершен',
  CREATED: 'Новый',
  SENT: 'Отправлен',
  DELETED: 'Расформирован',
};

export const rejectStatuses: Record<string, string> = {
  IN_PROCESS: 'В работе',
  NONE: ' ',
  DRAFT: 'Черновик',
  FORMED: 'Сформирован',
  SENDING_IN_PROCESS: 'Подготовка к отправке',
  COMPLETED: 'Завершен',
  CREATED: 'Новый',
  SENT: 'Отправлен',
  DELETED: 'Расформирован',
};

export const lossPayoffStatuses = {
  [PAYMENT_STATUSES.IN_PROCESS]: 'В работе',
  [PAYMENT_STATUSES.SENT_TO_PAYOFF]: 'Передан на выплату',
  [PAYMENT_STATUSES.PAID]: 'Платеж оплачен',
  [PAYMENT_STATUSES.RETURNED]: 'Возврат платежа',
  NONE: ' ',
};

export const ROLE = {
  ADMIN: 'ROLE_ADMIN',
  EXPERT: 'ROLE_UKS_LOSS_EXPERT',
  HEAD: 'ROLE_UKS_LOSS_HEAD',
  SPECIALIST: 'ROLE_UKS_LOSS_SPECIALIST',
  VICE_HEAD: 'ROLE_UKS_LOSS_VICE_HEAD',
  NONE: ' ',
};

/**
 * @deprecated use LOSS_STATUSES from 'src/types/loss/index.ts'
 */
export const LOSS_STATUS = LOSS_STATUSES;

export const legalOrganizationalOptions: {
  [key: string]: string;
} = {
  OOO: 'ООО',
  OAO: 'ОАО',
  PAO: 'ПАО',
  ZAO: 'ЗАО',
  AO: 'АО',
  INDIVIDUAL_ENTREPRENEUR: 'ИП',
};

export const evaluationTypeOptions: {
  [key: string]: string;
} = {
  BY_EXPERT: 'Единовременная выплата',
  BY_PERIOD: 'За период',
  BY_TABLE: 'Табличная выплата',
};

export const responsiblePersonDocumentOptions: {
  [key: string]: string;
} = {
  POWER_OF_ATTORNEY: 'Доверенность',
  ORDER: 'Приказ',
  REGULATIONS: 'Устав',
};

export const errorsTypes = [
  {
    title: 'Нет данных о заключении договора',
    name: 'isPolicyNotConcluded',
  },
  {
    title: 'Нет оплаты',
    name: 'isPolicyNotPaid',
  },
  {
    title: 'Нет подписи Страхователя',
    name: 'isPolicyNotSignedByPolicyholder',
  },
  {
    title: 'ФИО Страхователя в договоре иные',
    name: 'hasPolicyholderDifferentFioInContract',
  },
  {
    title: 'Нет № договора',
    name: 'hasNoPolicyNumber',
  },
  {
    title: 'Ошибка в № договора',
    name: 'isErrorInPolicyNumber',
  },
  {
    title: 'Нет заявления',
    name: 'hasNoPolicyholderApplication',
  },
  {
    title: 'Нет реквизитов',
    name: 'hasNoRequisites',
  },
  {
    title: 'Нет БИК Банка',
    name: 'hasNoBankBik',
  },
  {
    title: 'Нет № счета получателя',
    name: 'hasNoPaymentReceiverAccountNumber',
  },

  {
    title: 'Неверные реквизиты',
    name: 'isWrongPaymentReceiverRequisites',
  },
  {
    title: 'Указан счет АСЖ',
    name: 'isInsurerAccount',
  },
  {
    title: 'Валютный счет',
    name: 'isAccountInForeignCurrency',
  },
  {
    title: 'Счет нерезидента',
    name: 'isNotResidentAccount',
  },
  {
    title: 'Счет нерезидента (паспорт РФ)',
    name: 'isNotResidentAccountAndRussianPassport',
  },
  {
    title: '№ карты, нет л/с для зачисления',
    name: 'isCardNumberWithoutBankAccount',
  },
  {
    title: 'Карточный счет, нет № карты',
    name: 'isCardAccountWithoutCardNumber',
  },
  {
    title: 'Клиент не изменил реквизиты',
    name: 'wasNotClientRequisitesChanged',
  },
  {
    title: 'Некорректный счет',
    name: 'isWrongAccount',
  },
  {
    title: 'Счет юридического лица',
    name: 'isEntityAccount',
  },
  {
    title: 'Выплата третьему лицу',
    name: 'isPayoffReceiverThirdPerson',
  },
];

export const cooloffErrors: Record<string, string> = {
  // Старый вариант статусов.

  //POLICY_NOT_CONCLUDED: 'Отсуствуют данные о заключении полиса',
  //OTHER_ERROR: 'Другая ошибка',
  //MONEY_ENROLLMENT_IMPOSSIBLE: 'Зачисление ДС невозможно',
  //BANK_ACCOUNT: 'Счет Банка, несоответствие ЛС для зачисления',
  //ACCOUNT_CLOSED: 'Счет закрыт',
  //ACCOUNT_AND_NAME_INCOMPATIBILITY: 'Несоответствие номера счета и ФИО получателя',
  //ENTITY_ACCOUNT: 'Счет ЮЛ',
  //WRONG_ACCOUNT: 'Некорректный счет',
  //NOT_CLIENT_REQUISITES_CHANGED: 'Не изменены реквизиты клиента',
  //POLICY_NOT_PAID: 'Полис не оплачен',
  //POLICY_NOT_SIGNED_BY_POLICYHOLDER: 'Нет подписи страхователя',
  //HAS_NOT_POLICY_NUMBER: 'Отсуствует номер полиса',
  //ERROR_IN_POLICY_NUMBER: 'Ошибка в номере полиса',
  //HAS_NOT_PAYMENT_RECEIVER_ACCOUNT_NUMBER: 'Нет номера счета получателя',
  //HAS_NOT_POLICYHOLDER_APPLICATION: 'Нет заявления страхователя',
  //HAS_NOT_BANK_BINK: 'Отсуствует БИК Банка',
  //WRONG_PAYMENT_RECEIVER_REQUISITES: 'Некорректные реквизиты получателя',
  //INSURER_ACCOUNT: 'Указан счет АСЖ',
  //NOT_RESIDENT_ACCOUNT: 'Счет нерезидента',
  //NOT_RESIDENT_ACCOUNT_AND_RUSSIAN_PASSPORT: 'Счет нерезидента (Паспорт РФ)',
  //CARD_ACCOUNT_WITHOUT_CARD_NUMBER: 'Карточный номер, нет номера карты',
  //CARD_NUMBER_WITHOUT_BANK_ACCOUNT: 'Карточный номер, нет ЛС для зачисления',
  //CANCEL_BY_POLICYHOLDER: 'Отказ страхователя от расторжения',
  //PAID_BY_BANK: 'Возврат на стороне Банка',
  //DUPLICATE: 'Дубль',
  //ACCOUNT_IN_FOREIGN_CURRENCY: 'Счет в иностранной валюте',
  //CLIENT_REQUEST_DATE_GREATER_THEN_COOLOFF_TILL_DATE:
  //  'Дата подачи заявления клиентом превышает период Cooloff',

  HAS_NO_VIOLATIONS: 'Нарушений нет',
  HAS_NO_REQUISITES: 'Нет реквизитов',
  IS_PAYOFF_RECEIVER_THIRD_PERSON: 'Выплата третьему лицу',
  HAS_POLICYHOLDER_DIFFERENT_FIO_IN_CONTRACT: 'ФИО Страхователя в договоре иные',
  IS_OTHER_ERROR: 'ручной ввод',
  IS_POLICY_NOT_CONCLUDED: 'Нет данных о заключении договора страхования',
  IS_MONEY_ENROLLMENT_IMPOSSIBLE: 'Неверные реквизиты (зачисление невозможно)',
  IS_BANK_ACCOUNT: 'Неверные реквизиты (указан счет банка, нет лицевого счета для зачисления)',
  IS_ACCOUNT_CLOSED: 'Неверные реквизиты (счет закрыт)',
  IS_ACCOUNT_AND_NAME_INCOMPATIBILITY: 'Неверные реквизиты (ФИО получателя не соответствует номеру счета получателя )',
  IS_ENTITY_ACCOUNT: 'Указан счет юридического лица',
  WAS_NOT_CLIENT_REQUISITES_CHANGED: 'Реквизиты, предоставленные Вами ранее, не изменены',
  IS_WRONG_ACCOUNT: 'Неверные реквизиты (некорректный счет)',
  IS_POLICY_NOT_PAID: 'Нет оплаты',
  IS_POLICY_NOT_SIGNED_BY_POLICYHOLDER: 'Отсутствует подпись Страхователя',
  HAS_NO_POLICY_NUMBER: 'Отсутствует номер договора страхования',
  IS_ERROR_IN_POLICY_NUMBER: 'Ошибка в номере договора страхования: номер не соответствует данным договора страхования',
  HAS_NO_PAYMENT_RECEIVER_ACCOUNT_NUMBER: 'Отсутствует № счета получателя',
  HAS_NO_POLICYHOLDER_APPLICATION: 'Отсутствует заявление о расторжении договора',
  HAS_NO_BANK_BIK: 'Отсутствует БИК банка',
  IS_WRONG_PAYMENT_RECEIVER_REQUISITES: 'Неверные реквизиты',
  IS_INSURER_ACCOUNT: 'Неверные реквизиты (указан счет ООО "АльфаСтрахование-Жизнь")',
  IS_ACCOUNT_IN_FOREIGN_CURRENCY: 'Неверные реквизиты (указан валютный счет, необходимо указать рублевый)',
  IS_NOT_RESIDENT_ACCOUNT:
    'Неверные реквизиты (указан счет нерезидента, необходимо либо предоставить копию паспорта нерезидента, либо реквизиты резидента)',
  IS_NOT_RESIDENT_ACCOUNT_AND_RUSSIAN_PASSPORT: 'Неверные реквизиты (необходимо предоставить реквизиты резидента)',
  IS_CARD_ACCOUNT_WITHOUT_CARD_NUMBER: 'Неверные реквизиты (указан карточный счет, необходимо указать № карты)',
  IS_CARD_NUMBER_WITHOUT_BANK_ACCOUNT: 'Неверные реквизиты (указан номер карты, нет лицевого счета для зачисления)',
  OTHER_ERROR_COMMENT: 'Другие ошибки',
  IS_CLIENT_REQUEST_DATE_GREATER_THAN_COOLOFF_TILL_DATE:
    'Дата заявления на Cool-off  больше даты окончания периода Cool-off',
  IS_CREDIT_CLOSED_CONFIRMATION: 'Отсутствует справка о ПДП',
  PAYOFF_DENIED_INSURANCE_CONDITIONS: 'Отказ в выплате (условия страхования)',
  CREDIT_REPAYMENT_NOT_ON_TIME: 'Отказ погашение кредита не в срок',
  APPEAL_NOT_ON_TIME_DENIED: 'Обращение в СК не в срок',
};

export const coolOffStatus: Record<string, string> = {
  ACCEPTED_NOT_CASH: 'Принято б/н',
  ACCEPTED_CASH: 'Принято наличные',
  ACCEPTED_POSTAL_TRANSFER: 'Принято почтовый перевод',
  NEW_REG_CARD: 'Новое СЭД',
  REGISTERED: 'Зарегистрировано',
  REGISTERED_BY_MISTAKE: 'Ошибочная регистрация',
  DUPLICATE: 'Дубль',
  CANCELED_BY_POLICYHOLDER: 'Отказ Страхователя от расторжения',
  PAID_BY_BANK: 'Возврат на стороне банка',
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ в выплате',
  REQUEST_SENT_TO_DEPARTMENT: 'Отправлен запрос в подразделение',
  REQUEST_SENT_TO_BANK: 'Отправлен запрос в банк',
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
  RETURNED_PAYOFF: 'Возврат из ФД',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  NOTIFICATION_ABOUT_CASH_PAYMENT: 'Уведомление клиента о выплате наличными',
  NOTIFICATION_ABOUT_NOT_CASH_PAYMENT: 'Уведомление клиента о выплате безналичными',
  NOTIFICATION_ABOUT_POSTAL_TRANSFER_PAYMENT: 'Уведомление клиента о выплате почтовым переводом',
  TO_BANK_CANCELLATION: 'На аннулирование в банк',
  SENT_TO_BANK_FOR_CANCELLATION: 'Отправлено на аннулирование в банк',
  APPEAL_NOT_ON_TIME_DENIED: 'Обращение в СК не в срок',
};

export const terminationAddStatus: Record<string, string> = {
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
};

export const coolOffReportAddStatus: Record<string, string> = {
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ в выплате',
  ACCEPTED_CASH: 'Принято наличные',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  PAYOFF_DENIED_HAS_NO_VIOLATIONS: 'Отказ в выплате нарушений нет',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате убыток выплачен',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента убыток',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
};

export const policySelectionParameter: Record<string, string> = {
  POLICY_COVER_START_DATE: 'По дате начала ответственности',
  INCOMING_PAYMENT_DATE: 'По дате платежного поручения',
  INCOMING_PAYMENT_FACT_DATE: 'По дате поступления ДС на счет',
  POLICY_APPLICATION_DATE: 'По дате заключения полиса',
};

export const colorActStatus: Record<string, string> = {
  CREATED: 'grey',
  APPROVED: 'green',
  SENT_TO_PAYOFF: 'green',
  PAID: 'green',
  CANCELED: 'red',
};

export const errorsTypesInCooloffPayoffTab = [
  {
    title: 'Счет закрыт',
    name: 'isAccountClosed',
  },
  {
    title: 'Несоответствие ФИО и № счета',
    name: 'isAccountAndNameIncompatibility',
  },
  {
    title: 'Счет банка, нет л/с для зачисления',
    name: 'isBankAccount',
  },
  {
    title: 'Зачисление невозможно',
    name: 'isMoneyEnrollmentImpossible',
  },
];

export const coolOffReceiver: Record<string, string> = {
  BANK: 'Банк',
  OPERU_OPERU_DEPARTMENT: 'ОО ОПЕРУ',
  OPERU_BUSINESS_SUPPORT_DEPARTMENT: 'ОПБ ОПЕРУ',
  BUSINESS_DEPARTMENT: 'УРБ',
  UKS_CLIENT_SERVICE_DEPARTMENT: 'ОКС УКС',
  UKS_LOSS_DEPARTMENT: 'ОУУ УКС',
  FINANCIAL_DEPARTMENT: 'Финансовый департамент',
  LEGAL_DEPARTMENT: 'Юридический департамент',
};

export const deliveryFormatDict: Record<string, string> = {
  ORIGINAL: 'Оригинал',
  COPY: 'Копия',
  COPY_TO_WORK: 'Копия в работу',
  TELEGRAM: 'Телеграмма',
};

export const terminationSubTypeOptions: Record<string, string> = {
  CANCEL: 'Cooloff ДО вступления в силу',
  CLF: 'Cooloff ПОСЛЕ вступления в силу',
  PART: 'Частичный Cool-off',
};

export const letterTypes: {
  [key: string]: string;
} = {
  SIMPLE: 'Простое',
  REGISTERED: 'Заказное',
};

export const labelTypes: {
  [key: string]: string;
} = {
  //A4_PERSONAL_INFO: 'А4 с персональными данных',
  A4_NO_PERSONAL_INFO: 'А4 без персональных данных',
  //A5_PERSONAL_INFO: 'А5 с персональными данных',
  //A5_NO_PERSONAL_INFO: 'А5 без персональных данных',
  //A65_PERSONAL_INFO: 'С65 с персональными данных',
  C65_NO_PERSONAL_INFO: 'С65 без персональных данных',
};

export const assigneeTypes: Record<string, any> = {
  SPECIALIST: 'У специалиста',
  EXPERT: 'У эксперта',
  NONE: 'Ни у кого',
};

export const pdpReceiver: Record<string, string> = {
  BANK: 'Банк',
  OPERU_OPERU_DEPARTMENT: 'ОО ОПЕРУ',
  OPERU_BUSINESS_SUPPORT_DEPARTMENT: 'ОПБ ОПЕРУ',
  BUSINESS_DEPARTMENT: 'УРБ',
  UKS_CLIENT_SERVICE_DEPARTMENT: 'ОКС УКС',
  UKS_LOSS_DEPARTMENT: 'ОУУ УКС',
  FINANCIAL_DEPARTMENT: 'Финансовый департамент',
  LEGAL_DEPARTMENT: 'Юридический департамент',
};

export const errorsTypesInPdpPayoffTab: Record<string, string> = {
  isAccountClosed: 'Счет закрыт',
  isAccountAndNameIncompatibility: 'Несоответствие ФИО и № счета',
  isBankAccount: 'Счет Банка, нет л/с для зачисления',
  isMoneyEnrollmentImpossible: 'Зачисление невозможно',
};

export const pdpAddStatus: Record<string, string> = {
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
};

export const pdpDeliveryFormatDict: Record<string, string> = {
  ORIGINAL: 'Оригинал',
  COPY: 'Копия',
  COPY_TO_WORK: 'Копия в работу',
  TELEGRAM: 'Телеграмма',
};

/**
 * Сокращенная запись периодов
 */
export const shortLabelPeriods: Record<string, string> = {
  MONTH: 'мес(лет)',
  YEAR: 'лет',
  WEEK: 'нед.',
  DAY: 'дн.',
  NO_DATA: '',
};

export const productParam: Record<string, string> = {
  CREDIT_CONTRACT_NUMBER: 'Номер счета к кредитной карте',
  CONTRAGENT_INSURANCE_SUM_CONCLUSION_DATE: 'ФИО страхователя + Дата заключения + Страховая сумма',
};

export const medoblakoActStatus: Record<string, string> = {
  RECORDS_LINKING: 'Добавление записей в акт',
  RECORDS_LINKED: 'Записи добавлены в акт',
  TOTALS_CALCULATED: 'Сформирован',
  APPROVED: 'Согласован',
  SENT_TO_PAYOFF: 'Отправлен на выплату',
  PAID: 'Выплачен',
  REJECTED: 'Отменен',
  RETURNED_PAYOFF: 'Возвращен',
};

export const misinformationStatusForFiltersMy: Record<string, string> = {
  RETURNED_PAYOFF: 'Возврат из ФД',
  REGISTERED: 'Зарегистрировано',
  PAYOFF_DENIED_HAS_NO_VIOLATIONS: 'Отказ в выплате нарушений нет',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате убыток выплачен',
  REQUEST_SENT_TO_BANK: 'Отправлен запрос в банк',
  REQUEST_SENT_TO_DEPARTMENT: 'Отправлен запрос в подразделение',
  ERRORS: 'Ошибки',
  ACCEPTED_NOT_CASH: 'Принято б/н',
  ACCEPTED_CASH: 'Принято наличные',
  ACCEPTED_POSTAL_TRANSFER: 'Принято почтовый перевод',
  NOTIFICATION_ABOUT_CASH_PAYMENT: 'Уведомление клиента о выплате наличными',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента убыток заявлен',
};

export const misinformationStatusForFilters: Record<string, string> = {
  ...misinformationStatusForFiltersMy,
  REGISTERED_BY_MISTAKE: 'Ошибочная регистрация',
  DUPLICATE: 'Дубль',
  CANCELED_BY_POLICYHOLDER: 'Отказ Страхователя от расторжения',
  PAID_BY_BANK: 'Возврат на стороне банка',
  PAYOFF_DENIED: 'Отказ',
  SENT_TO_PAYOFF: 'Передано на выплату',
  PAID: 'Выплачено',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
};

export const misinformationStatus: Record<string, string> = {
  ...misinformationStatusForFilters,
  NEW_REG_CARD: 'Новое СЭД',
  NOTIFICATION_ABOUT_NOT_CASH_PAYMENT: 'Уведомление клиента о выплате безналичными',
  NOTIFICATION_ABOUT_POSTAL_TRANSFER_PAYMENT: 'Уведомление клиента о выплате почтовым переводом',
  TO_BANK_CANCELLATION: 'На аннулирование в банк',
  SENT_TO_BANK_FOR_CANCELLATION: 'Отправлено на аннулирование в банк',
};

export const misinformationReportAddStatus: Record<string, string> = {
  ERRORS: 'Ошибки',
  PAYOFF_DENIED: 'Отказ',
  ACCEPTED_CASH: 'Принято наличные',
  SENT_LETTER_WITH_ERRORS: 'Передано на отправку письма ошибки',
  SENT_LETTER_WITH_DENIAL: 'Передано на отправку письма отказ',
  SENT_LETTER_WITH_CASH_PAYOFF: 'Передано на отправку письма наличные',
  PAYOFF_DENIED_HAS_NO_VIOLATIONS: 'Отказ в выплате нарушений нет',
  PAYOFF_DENIED_LOSS_PAID: 'Отказ в выплате убыток выплачен',
  APPEAL_NOT_ON_TIME_DENIED: 'Отказ обращение в СК не в срок',
  NOTIFICATION_ABOUT_LOSS_IN_PROCESS: 'Уведомление клиента убыток заявлен',
  SENT_LETTER_WITH_LOSS: 'Передано на отправку письма убыток',
};
