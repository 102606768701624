import { api } from 'services/get-api-host';
import { rtk } from 'shared/api';

import { AuthResponse } from './types';
import { getHashPassword } from './utils';

export const authApi = rtk.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, { captchaToken?: string; login: string; password: string }>({
      query: ({ password, login, captchaToken }) => ({
        url: api.auth.login,
        method: 'POST',
        credentials: 'include',
        body: {
          login,
          captchaToken,
          passwordHash: getHashPassword(password),
        },
      }),
      invalidatesTags: ['profile'],
    }),
    logout: builder.mutation<AuthResponse, void>({
      query: () => ({
        url: api.auth.logout,
        method: 'POST',
        credentials: 'include',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
