import { Gender, Options, Relation, PaymentTypes } from 'types/general';
import {
  regestryStatuses,
  policyStatuses,
  policySubStatuses,
  pdpAddStatus,
  pdpDeliveryFormatDict,
} from 'utils/dictionaries';

import { objectToOptions } from '../functions';

export const genderShortOptions: Options = [
  {
    label: `${Gender.MALE.slice(0, 3)}.`,
    value: 'MALE',
  },
  {
    label: `${Gender.FEMALE.slice(0, 3)}.`,
    value: 'FEMALE',
  },
];

export const genderOptions: Options = [
  {
    label: Gender.MALE,
    value: 'MALE',
  },
  {
    label: Gender.FEMALE,
    value: 'FEMALE',
  },
];

export const paymentType: Options = [
  {
    label: PaymentTypes.CASH,
    value: 'CASH',
  },
  {
    label: PaymentTypes.NONCASH,
    value: 'NOT_CASH',
  },
  {
    label: PaymentTypes.POSTAL_TRANSFER,
    value: 'POSTAL_TRANSFER',
  },
];

export const booleanOptions: Options = [
  {
    label: 'Да',
    value: 'true',
  },
  {
    label: 'Нет',
    value: 'false',
  },
];

export const registryStatusesOptions: Options = objectToOptions(regestryStatuses);
export const policyStatusesOptions: Options = objectToOptions(policyStatuses);
export const policySubStatusesOptions: Options = objectToOptions(policySubStatuses);
export const pdpAddStatusOptions = objectToOptions(pdpAddStatus);
export const optionDeliveryFilter = objectToOptions(pdpDeliveryFormatDict);
export const taskNameOptions: Options = [
  {
    label: 'Подготовить убыток',
    value: 'Подготовить убыток',
  },
  {
    label: 'Обработать убыток',
    value: 'Обработать убыток',
  },
  {
    label: 'Обработать уведомление',
    value: 'Обработать уведомление',
  },
  {
    label: 'Возврат платежа',
    value: 'Возврат платежа',
  },
];

export const objectTypeOptions: Options = [
  {
    label: 'Убыток',
    value: 'LOSS',
  },
  {
    label: 'РК',
    value: 'REG_CARD',
  },
];

export const statusOptions: Options = [
  {
    label: 'Оплачен',
    value: 'PAID',
  },
  {
    label: 'В работе',
    value: 'IN_PROCESS',
  },
  {
    label: 'Уведомление',
    value: 'NOTIFICATION',
  },
  {
    label: 'Возврат платежа',
    value: 'RETURNED_PAYOFF',
  },
];

export const roleOptions: Options = [
  {
    label: 'Специалист ОУУ',
    value: 'ROLE_UKS_LOSS_SPECIALIST',
  },
  {
    label: 'Эксперт ОУУ',
    value: 'ROLE_UKS_LOSS_EXPERT',
  },
];

export const paymentsSystemOptions: Options = [
  {
    label: 'AlfaConnect',
    value: 'NIS',
  },
  {
    label: 'ИНСИС',
    value: 'INSIS',
  },
  {
    label: 'Не определена',
    value: 'FREE',
  },
];
