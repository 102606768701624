import { createSlice } from '@reduxjs/toolkit';
import { Profile } from 'modules/user-administration/types/users';
import { authApi } from 'services/auth';
import { userAdministrationApi } from 'services/user-administration';

type ProfileState = {
  profile?: Profile;
};

const initialState: ProfileState = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    logout(state) {
      state.profile = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userAdministrationApi.endpoints.getProfile.matchFulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.profile = undefined;
      });
  },
});
