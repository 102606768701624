import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

import { LayoutPopup, BaseButton } from '@asg/core';
import type { ErrorResponse } from 'types/general';

import 'assets/styles/error.scss';

// eslint-disable-next-line no-spaced-func
export const ErrorContext = createContext<[ErrorResponse | null, Dispatch<SetStateAction<ErrorResponse | null>>]>([
  null,
  () => {},
]);

const ErrorProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [error, setError] = useState<ErrorResponse | null>(null);

  return (
    <ErrorContext.Provider value={[error, setError]}>
      {children}
      <LayoutPopup
        visible={!!error}
        title={`Ошибка ${error?.status || ''}`}
        onCancel={() => setError(null)}
        width={300}
        className="Error"
      >
        <div className="d-flex flex-column align-items-center mt-14">
          <span className="fs-2">{error?.error}</span>
          <span className="color-red">{error?.message}</span>
          <BaseButton
            label="Закрыть"
            onClick={() => setError(null)}
            className="mt-30"
          />
        </div>
      </LayoutPopup>
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
