import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const getDivision = (builder: RtkBuilder) =>
  builder.query<GetDivisionResponse, string>({
    query: (name) => ({
      url: api.userAdministration.divisionList + `/${name}`,
    }),
  });

type GetDivisionResponse = {
  isDeleted: boolean;
  name: string;
  parentName: string;
  title: string;
};
