import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const saveDivision = (builder: RtkBuilder) =>
  builder.mutation<SaveDivisionBode, { division: SaveDivisionBode; create: boolean }>({
    query: ({ division, create }) => ({
      url: api.userAdministration.divisionList + (create ? `/${division.name}` : ''),
      method: !create ? 'PUT' : 'POST',
      body: division,
    }),
  });

type SaveDivisionBode = {
  isDeleted: boolean;
  name: string;
  permissionGroups: string[];
  title: string;
};
