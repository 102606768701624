import React from 'react';

import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { momentDate } from '@asg/core';
import 'bootstrap-4-grid';

import App from './app';

import '@asg/core/index.css';
import 'assets/styles/common.scss';

import './index.scss';

if (import.meta.env.VITE_CI_TIMESTAMP) {
  // eslint-disable-next-line no-console
  console.group('BUILD INFO');
  console.log(`Last commit's time: ${momentDate(import.meta.env.VITE_CI_TIMESTAMP).formatWithTime() || ''}`);
  console.log(`Last commit's SHA: ${import.meta.env.VITE_CI_COMMIT_SHORT_SHA}`);
  console.log(`@asg/core version: ${import.meta.env.VITE_CORE_VERSION}`);
  console.log(`production mode: ${import.meta.env.VITE_PRODUCTION}`);
  // eslint-disable-next-line no-console
  console.groupEnd();
}

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
