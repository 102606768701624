import React, { createContext, useContext } from 'react';

import { useFetch } from '@asg/core';
import { useGetDivisionOptions, useGetPermissionOptions, useGetRoleOptions } from 'modules/user-administration';
import { api } from 'services/get-api-host';
import type { Options } from 'types/general';
import { genderOptions } from 'utils/options';

import { ProductCoverDict } from '../types/products';

type ListContext = {
  divisions: Options;
  divisionsLoading: boolean;

  permissionsGroup: Options;
  permissionLoading: boolean;

  productDict: ProductCoverDict[];
  productDictLoading: boolean;

  roles: {
    value: any;
    label: string;
    isDeleted: boolean;
    options?: Options;
  }[];
  rolesLoading: boolean;
  updateRoles: () => void;

  genders: Options;
};

export const ListsContext = createContext<ListContext>({
  divisions: [],
  divisionsLoading: false,

  permissionsGroup: [],
  permissionLoading: false,

  productDict: [],
  productDictLoading: false,

  roles: [],
  rolesLoading: false,
  updateRoles: () => {},

  genders: [],
});

export const useListContext = () => useContext(ListsContext);

const ListsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { data: divisions, loading: divisionsLoading } = useGetDivisionOptions();
  const { data: roles, loading: rolesLoading, get: updateRoles } = useGetRoleOptions();
  const { data: permissionsGroup, loading: permissionLoading } = useGetPermissionOptions();

  const { data: productDict, loading: productDictLoading } = useFetch(api.product.productDict, []);

  return (
    <ListsContext.Provider
      value={{
        divisions,
        divisionsLoading,

        permissionsGroup,
        permissionLoading,

        roles,
        rolesLoading,
        updateRoles,

        productDict,
        productDictLoading,

        genders: genderOptions,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};

export default ListsProvider;
