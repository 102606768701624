const routes = {
  auth: {
    url: '/auth',
    login: {
      url: '/login',
    },
    registration: {
      url: '/registration',
    },
    setPassword: {
      url: '/set-password',
    },
  },
  cabinet: {
    url: '/cabinet',
    users: {
      url: '/users',
      user: {
        url: '/user/:id',
        card: {
          url: '/card',
        },
        log: {
          url: '/log',
        },
      },
      create: {
        url: '/create',
      },
    },
    reports: {
      url: '/reports',
      undistributed: {
        url: '/undistributed',
      },
      ordered: {
        url: '/ordered',
      },
      export: {
        url: '/export',
      },
    },
    orderedReports: {
      url: '/ordered-reports',
    },
    clients: {
      url: '/clients',
      list: {
        url: '/list',
        individual: {
          url: '/individual',
        },
        entity: {
          url: '/entity',
        },
      },
      client: {
        individual: {
          url: '/individual/:id',
          card: {
            url: '/card',
          },
          requisites: {
            url: '/requisites',
          },
          contacts: {
            url: '/contacts',
          },
          policies: {
            url: '/policies',
          },
          protocol: {
            url: '/protocol',
          },
        },
        entity: {
          url: '/entity/:id',
          card: {
            url: '/card',
          },
          requisites: {
            url: '/requisites',
          },
          contacts: {
            url: '/contacts',
          },
          policies: {
            url: '/policies',
          },
          protocol: {
            url: '/protocol',
          },
        },
      },
    },
    clientsV2: {
      url: '/clients-v2',
      list: {
        url: '/list',
        individual: {
          url: '/individual',
        },
        entity: {
          url: '/entity',
        },
      },
      client: {
        individual: {
          url: '/individual/:id/',
          card: {
            url: '/card',
          },
          policies: {
            url: '/policies',
          },
        },
        entity: {
          url: '/entity/:id/',
          card: {
            url: '/card',
          },
          policies: {
            url: '/policies',
          },
        },
      },
    },

    divisions: {
      url: '/divisions',
      division: {
        url: '/division/:id',
        card: {
          url: '/card',
        },
        log: {
          url: '/log',
        },
        role: {
          url: '/role',
        },
        permissionGroup: {
          url: '/permission-group',
        },
      },
      create: {
        url: '/create',
      },
    },
    agents: {
      url: '/agents',
      agent: {
        url: '/agent/:id',
        card: {
          url: '/card',
        },
        log: {
          url: '/log',
        },
      },
      create: {
        url: '/create',
      },
    },

    commissions: {
      url: '/commissions',
      list: {
        url: '/list',
      },
      card: {
        url: '/:id',
        all: {
          url: '/all',
        },
        termination: {
          url: '/termination',
        },
        payment: {
          url: '/payment',
        },
      },
    },

    rko: {
      url: '/rko',
      list: {
        url: '/list',
      },
      card: {
        url: '/:id/',
        all: {
          url: 'all',
        },
        statusPayment: {
          url: 'status-payment',
        },
      },
    },

    forecast: {
      url: '/forecast',
      list: {
        url: '/list',
      },
      card: {
        url: '/:id',
      },
    },

    payments: {
      url: '/payments',
      rko: {
        url: '/rko',
        incoming: {
          url: '/incoming',
          card: {
            url: '/:id',
          },
        },
        outgoing: {
          url: '/outgoing',
          card: {
            url: '/:id',
          },
        },
      },
      incoming: {
        url: '/incoming',
        payment: {
          url: '/:id',
          card: {
            url: '/card/',
          },
          log: {
            url: '/log/',
          },
          bindings: {
            url: '/bindings/',
          },
          bindingsWithPolicies: {
            url: '/bindings-with-policies/',
          },
        },
      },
      outgoing: {
        url: '/outgoing',
        payment: {
          url: '/:id/',
          card: {
            url: '/card/',
          },
          log: {
            url: '/log/',
          },
          bindings: {
            url: '/bindings/',
          },
        },
      },
    },
    paymentsV2: {
      url: '/payments-v2',
      incoming: {
        url: '/incoming',
        payment: {
          url: '/:id/',
          card: {
            url: '/card/',
          },
          log: {
            url: '/log/',
          },
          bindings: {
            url: '/bindings/',
          },
          bindingsWithPolicies: {
            url: '/bindings-with-policies/',
          },
        },
      },
      outgoing: {
        url: '/outgoing',
        payment: {
          url: '/:id/',
          card: {
            url: '/card/',
          },
          log: {
            url: '/log/',
          },
          bindings: {
            url: '/bindings/',
          },
        },
      },
    },

    services: {
      url: '/services',
      partners: {
        url: '/partners',
        partner: {
          url: '/:id',
          card: {
            url: '/card',
          },
          docs: {
            url: '/docs',
          },
          log: {
            url: '/log',
          },
        },
      },
      registryLoss: {
        url: '/registry-loss',
        card: {
          url: '/:id',
          registry: {
            url: '/registry',
          },
        },
      },
      overallReport: {
        url: '/overall_report',
      },
      partnersList: {
        url: '/partners-list',
        partner: {
          url: '/:id',
          card: {
            url: '/card',
          },
          docs: {
            url: '/docs',
          },
          log: {
            url: '/log',
          },
        },
      },
      reports: {
        url: '/reports',
        report: {
          url: '/:id',
          attachment: {
            url: '/attachment',
          },
          detachment: {
            url: '/detachment',
          },
          payment: {
            url: '/payment',
          },
          card: {
            url: '/card',
          },
          docs: {
            url: '/docs',
          },
          log: {
            url: '/log',
          },
        },
      },
    },
    roles: {
      url: '/roles',
      role: {
        url: '/role/:id',
        card: {
          url: '/card',
        },
        log: {
          url: '/log',
        },
      },
      create: {
        url: '/create',
      },
    },

    policies: {
      url: '/policies',
      policy: {
        url: '/:id',
        insured: {
          url: '/insured',
        },
        policiesMain: {
          url: '/card',
        },
        policiesCoversAndRisks: {
          url: '/card/policies-covers-and-risks',
        },
        policiesLosses: {
          url: '/card/policies-losses',
        },
        policiesDocs: {
          url: '/card/policies-docs',
        },
        policiesCancel: {
          url: '/card/policies-cancel',
        },
        policiesAcquisition: {
          url: '/card/policies-acquisition',
        },
        policiesAddendum: {
          url: '/card/policies-addendum',
        },
        policiesPrintedForms: {
          url: '/card/policies-printedforms',
        },
        policiesService: {
          url: '/card/policies-service',
        },
        policiesEventsLog: {
          url: '/card/policies-events-log',
        },
        payments: {
          url: '/card/payments',
        },
        reinsurance: {
          url: '/card/reinsurance',
        },
        protocol: {
          url: '/card/protocol',
        },
      },
    },
    policiesV2: {
      url: '/policies-v2',
      policy: {
        url: '/:id',
        insured: {
          url: '/insured',
        },
        policiesMain: {
          url: '/card',
        },
        policiesCoversAndRisks: {
          url: '/card/policies-covers-and-risks',
        },
        policiesLosses: {
          url: '/card/policies-losses',
        },
        policiesDocs: {
          url: '/card/policies-docs',
        },
        policiesCancel: {
          url: '/card/policies-cancel',
        },
        policiesAcquisition: {
          url: '/card/policies-acquisition',
        },
        policiesAddendum: {
          url: '/card/policies-addendum',
        },
        policiesPrintedForms: {
          url: '/card/policies-printedforms',
        },
        policiesService: {
          url: '/card/policies-service',
        },
        policiesEventsLog: {
          url: '/card/policies-events-log',
        },
      },
    },

    policiesCollective: {
      url: '/policies-collective',
      contract: {
        url: '/:id',
        condition: {
          url: '/condition',
        },
        documents: {
          url: '/documents',
        },
        policies: {
          url: '/policies',
        },
      },
    },
    policiesCollectiveV2: {
      url: '/policies-collective-v2',
      contract: {
        url: '/:id',
        condition: {
          url: '/condition',
        },
        documents: {
          url: '/documents',
        },
        policies: {
          url: '/policies',
        },
      },
    },

    registry: {
      url: '/registry',
      card: {
        url: '/card/:id',
        application: {
          url: '/application',
        },
        payoffStatus: {
          url: '/payoff-status',
        },
      },
      log: {
        url: '/card/:id/log',
      },
    },
    errorReconciliation: {
      url: '/registry-reconciliation',
    },
    requestRejectList: {
      url: '/request-reject-list',
    },
    losses: {
      url: '/losses',
      loss: {
        url: '/loss/:id',
        reg: {
          url: '/reg',
        },
        eval: {
          url: '/eval',
        },
        payment: {
          url: '/payment',
        },
        refuse: {
          url: '/refuse',
          card: {
            url: '/:refuseId',
            log: {
              url: '/log',
            },
          },
        },
        request: {
          url: '/request',
          card: {
            url: '/:requestId',
            log: {
              url: '/log',
            },
          },
        },
        payoff: {
          url: '/payment/payoff',
          card: {
            url: '/:payoffId',
            log: {
              url: '/log',
            },
          },
        },
        docs: {
          url: '/docs',
        },
        protocol: {
          url: '/protocol',
        },
        log: {
          url: '/log',
        },
      },
      create: {
        url: '/create',
      },
    },
    cooloffs: {
      url: '/cooloffs',
    },
    cooloffApplications: {
      url: '/cooloff-applications',
      my_sed: {
        url: '/my_sed',
      },
      all_sed: {
        url: '/all_sed',
      },
      my_cooloff: {
        url: '/my_cooloff',
      },
      all_cooloff: {
        url: '/all_cooloff',
      },
      sed_app_in_insis: {
        url: '/sed_app_in_insis',
      },
      card: {
        url: '/:id',
      },
      rk: {
        url: '/:id/rk',
      },
      application: {
        url: '/:id/application',
      },
      policy: {
        url: '/:id/policy',
      },
      options: {
        url: '/:id/options',
      },
      reEnsure: {
        url: '/:id/reensure',
      },
      payment: {
        url: '/:id/payment',
      },
      log: {
        url: '/:id/log',
      },
      documents: {
        url: '/:id/documents',
      },
    },
    unloading: {
      url: '/cooloff-unloading',
    },
    agentRequestTermination: {
      files: {
        url: '/agent-request-termination/files',
      },
      card: {
        url: '/agent-request-termination/files/:id',
        related: {
          url: '/related',
        },
        registers: {
          url: '/registers',
          card: {
            url: '/:registryId',
          },
        },
        letters: {
          url: '/letters',
          card: {
            url: '/:letterId',
          },
        },
      },
      policy: {
        url: '/policy',
      },
      letters: {
        url: '/agent-request-termination/letters',
        card: {
          url: '/:letterId',
        },
        policy: {
          url: '/:letterId/policy',
        },
        payment: {
          url: '/:letterId/payment',
        },
      },
    },
    individualTermination: {
      registry: {
        url: '/individual-termination/registry',
        card: {
          url: '/:registryId',
        },
      },
      registryAct: {
        url: '/individual-termination/registry-act',
        card: {
          url: '/:id',
        },
      },
      policyCancel: {
        url: '/individual-termination/policy-cancel',
      },
      termination: {
        url: '/individual-termination/termination',
        card: {
          url: '/:id',
          statement: {
            url: '/statement',
          },
          policy: {
            url: '/policy',
          },
          docs: {
            url: '/docs',
          },
          history: {
            url: '/history',
          },
        },
      },
      policy: {
        url: '/individual-termination/policy',
      },
      rnvErrors: {
        url: '/rnv-errors',
      },
    },
    cooloffWithTypeApplications: {
      url: '/cooloff-applications',
      card: {
        url: '/:type/:id',
      },
      rk: {
        url: '/:type/:id/rk',
      },
      application: {
        url: '/:type/:id/application',
      },
      policy: {
        url: '/:type/:id/policy',
      },
      options: {
        url: '/:type/:id/options',
      },
      reEnsure: {
        url: '/:type/:id/reensure',
      },
      payment: {
        url: '/:type/:id/payment',
      },
      log: {
        url: '/:type/:id/log',
      },
      documents: {
        url: '/:type/:id/documents',
      },
    },
    cooloffActs: {
      url: '/cooloff-acts',
      card: {
        url: '/:id',
      },
    },
    cooloffExport: {
      url: '/cooloff-export',
    },
    cooloffPoliciesTermination: {
      url: '/cooloff-policies-termination',
      card: {
        url: '/:id',
      },
    },
    cooloffErrors: {
      url: '/cooloff-errors',
      card: {
        url: '/:id',
      },
    },
    cooloffLetterReports: {
      url: '/cooloff-letter-reports',
      card: {
        url: '/:id',
      },
    },
    cooloffSummaryReports: {
      url: '/cooloff-summary-reports',
      card: {
        url: '/:id',
      },
    },
    cooloffManagement: {
      url: '/cooloff-management',
    },
    rnvErrors: {
      url: '/rnv-errors',
    },
    pdps: {
      url: '/pdps',
    },
    pdpApplications: {
      url: '/pdp-applications',
      my_sed: {
        url: '/my_sed',
      },
      all_sed: {
        url: '/all_sed',
      },
      my_pdp: {
        url: '/my_pdp',
      },
      all_pdp: {
        url: '/all_pdp',
      },
      sed_app_in_insis: {
        url: '/sed_app_in_insis',
      },
      card: {
        url: '/:type/:id',
      },
      rk: {
        url: '/:type/:id/rk',
      },
      application: {
        url: '/:type/:id/application',
      },
      policy: {
        url: '/:type/:id/policy',
      },
      options: {
        url: '/:type/:id/options',
      },
      reEnsure: {
        url: '/:type/:id/reensure',
      },
      payment: {
        url: '/:type/:id/payment',
      },
      log: {
        url: '/:type/:id/log',
      },
      documents: {
        url: '/:type/:id/documents',
      },
    },
    pdpReportExel: {
      url: '/pdp-report-exel',
    },
    pdpActs: {
      url: '/pdp-acts',
      card: {
        url: '/:id',
      },
    },
    pdpLetterReports: {
      url: '/pdp-letter-reports',
      card: {
        url: '/:id',
      },
    },
    pdpErrors: {
      url: '/pdp-errors',
      card: {
        url: '/:id',
      },
    },
    pdpPoliciesToBreak: {
      url: '/pdp-policies-to-break',
      card: {
        url: '/:id',
      },
    },
    registration: {
      url: '/loss-registration',
      card: {
        url: '/:id',
        card: {
          url: '/reg-card',
        },
        log: {
          url: '/reg-card-log',
        },
      },
    },
    tasks: {
      url: '/loss-tasks',
      card: {
        url: '/:id',
      },
    },
    management: {
      url: '/loss-managment',
      card: {
        url: '/:id',
      },
    },
    export: {
      url: '/loss-export',
      card: {
        url: '/:id',
      },
    },
    products: {
      url: '/products',
      card: {
        url: '/card/:id',
        main: {
          url: '/main',
        },
        covers: {
          url: '/covers',
        },
        agent: {
          url: '/agent',
        },
        aquisition: {
          url: '/aquisition',
        },
        termination: {
          url: '/termination',
        },
        creation: {
          url: '/creation',
        },
        ordinary: {
          url: '/ordinary',
        },
      },
    },
    productsV2: {
      url: '/products-v2',
      card: {
        url: '/card/:id',
        main: {
          url: '/main',
        },
        version: {
          url: '/version',
        },
        covers: {
          url: '/covers',
        },
        losses: {
          url: '/losses',
        },
        paymentPolicy: {
          url: '/payment-policy',
        },
        insurer: {
          url: '/insurer',
        },
        agent: {
          url: '/agent',
        },
        printForm: {
          url: '/print-form',
        },
        acquisition: {
          url: '/acquisition',
        },
        termination: {
          url: '/termination',
        },
        creation: {
          url: '/creation',
        },
        reinsurance: {
          url: '/reinsurance',
        },
        actuarialJournals: {
          url: '/actuarial-journals',
        },
        provodki: {
          url: '/provodki',
        },
        interfaces: {
          url: '/interfaces',
        },
        printForms: {
          url: '/print-forms',
        },
      },
    },
    config: {
      url: '/config',
      card: {
        url: '/card/:id',
        products: {
          url: '/products',
        },
      },
    },
    groups: {
      url: '/groups',
      card: {
        url: '/card/:id',
      },
    },
    bordereau: {
      url: '/bordereau',
      card: {
        url: '/:id',
      },
    },
    callCenter: {
      url: '/call-center',
      card: {
        url: '/:id',
      },
    },
    misinformation: {
      url: '/misinformation',
      applications: {
        url: '/applications',
        card: {
          url: '/:type/:id',
        },
        rk: {
          url: '/:type/:id/rk',
        },
        application: {
          url: '/:type/:id/application',
        },
        policy: {
          url: '/:type/:id/policy',
        },
        options: {
          url: '/:type/:id/options',
        },
        reEnsure: {
          url: '/:type/:id/reensure',
        },
        payment: {
          url: '/:type/:id/payment',
        },
        log: {
          url: '/:type/:id/log',
        },
        documents: {
          url: '/:type/:id/documents',
        },
      },
      policies: {
        url: '/policies',
      },
      acts: {
        url: '/acts',
        card: {
          url: '/:id',
        },
      },
      reports: {
        url: '/reports',
      },
      reportExel: {
        url: '/report-exel',
      },
      report: {
        url: '/reports',
        card: {
          url: '/:id',
        },
      },
      misinformationActs: {
        url: '/pdp-acts',
        card: {
          url: '/:id',
        },
      },
      misinformationLetterReports: {
        url: '/pdp-letter-reports',
        card: {
          url: '/:id',
        },
      },
      misinformationErrors: {
        url: '/pdp-errors',
        card: {
          url: '/:id',
        },
      },
      misinformationPoliciesToBreak: {
        url: '/pdp-policies-to-break',
        card: {
          url: '/:id',
        },
      },
    },
    accounting: {
      url: '/accounting',
    },
    docs: {
      url: '/docs',
    },
  },
};

type Route = {
  url?: string;
  [key: string]: any;
};

const getUrl = (startUrl: string | null, route: Route): typeof routes => {
  const checkRoute = { ...route };
  let url = startUrl;
  url += checkRoute.url || '';

  const nextRoute: {
    [key: string]: any;
  } = {};
  Object.entries(checkRoute).forEach(([key, v]) => {
    const value = v as Route;
    if (route && typeof value === 'object') {
      nextRoute[key] = getUrl(url, value);
    }
  });

  if (url) {
    nextRoute.url = url;
  }

  return {
    ...nextRoute,
  } as typeof routes;
};

const generatedLinks = getUrl('', routes as Route);

export default generatedLinks;
