import { configureStore } from '@reduxjs/toolkit';
import { rtk } from 'shared/api';
import { profileSlice } from 'slices';

export const store = configureStore({
  reducer: {
    [rtk.reducerPath]: rtk.reducer,
    [profileSlice.reducerPath]: profileSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtk.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
