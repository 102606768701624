import React from 'react';

import { TableGridFiltersProvider } from '@asg/core';
import ErrorProvider from 'contexts/error';
import ListsProvider from 'contexts/lists';
import LocationProvider from 'contexts/location';
import moment from 'moment';
import 'moment/locale/ru';

import { ApiProvider } from 'app/providers';

import AuthProvider from '../auth/provider';

moment.locale('ru', {
  week: {
    dow: 1,
  },
});

const RootProvider: React.FC = ({ children }) => (
  <ApiProvider>
    <LocationProvider>
      <ErrorProvider>
        <AuthProvider>
          <ListsProvider>
            <TableGridFiltersProvider>{children}</TableGridFiltersProvider>
          </ListsProvider>
        </AuthProvider>
      </ErrorProvider>
    </LocationProvider>
  </ApiProvider>
);

export default RootProvider;
