import { formatNumberMoney } from '@asg/core';
import moment from 'moment';
import { capitalize } from 'radash';

export const filterOptions = (data: { label: string; value: string }[]) => {
  return (
    data?.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    }) || []
  );
};

export const sortingUpdatedDatetime = (data: Record<string, any>[]): Record<string, any>[] => {
  return data
    ?.sort((a, b) => {
      if (moment(a?.updatedDatetime).isBefore(b?.updatedDatetime)) return 1;
      if (!moment(a?.updatedDatetime).isBefore(b?.updatedDatetime)) return -1;
      return 0;
    })
    .filter(Boolean);
};

export const sortingCreatedDatetime = (data: Record<string, any>[]): Record<string, any>[] => {
  return data
    ?.sort((a, b) => {
      if (moment(a?.createdDatetime).isBefore(b?.createdDatetime)) return 1;
      if (!moment(a?.createdDatetime).isBefore(b?.createdDatetime)) return -1;
      return 0;
    })
    .filter(Boolean);
};
export const sortingCreatedDatetimeTermination = (data: Record<string, any>[]): Record<string, any>[] => {
  return data
    ?.sort((a, b) => {
      if (moment(a?.termination?.createdDatetime).isBefore(b?.termination?.createdDatetime)) return 1;
      if (!moment(a?.termination?.createdDatetime).isBefore(b?.termination?.createdDatetime)) return -1;
      return 0;
    })
    .filter(Boolean);
};

export const getValuePolicyDate = (type?: string) => {
  if (type === 'CLIENT_APPLICATION_DATE') {
    return 'ДПЗ';
  } else if (type === 'LOAN_REPAYMENT_DATE') {
    return 'ДПК';
  }
  return '';
};

export const sortingDatetime = (data: Record<string, any>[], path = 'updatedDatetime'): Record<string, any>[] => {
  return data
    ?.sort((a, b) => {
      if (moment(a?.[path]).isBefore(b?.[path])) return 1;
      if (!moment(a?.[path]).isBefore(b?.[path])) return -1;
      return 0;
    })
    .filter(Boolean);
};
export const declension = (val: number, forms: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
};

export const termOfInsurance = (type: 'MONTH' | 'YEAR' | 'DAY', count: number) => {
  switch (type) {
    case 'MONTH':
      return `${count} мес.`;
    case 'YEAR':
      return `${count} ${String(declension(count, ['год', 'года', 'лет']))}`;
    case 'DAY':
      return `${count} ${String(declension(count, ['день', 'дня', 'дней']))}`;
    default:
      return '';
  }
};

export const getCover = (covers: Record<string, any>, index: number, key: string) =>
  ((covers[index] && covers[index][key]) || 0).toFixed(2);

export const getFio = (data?: { firstName?: string; lastName?: string; middleName?: string }): string => {
  let result = '';

  if (data) {
    if (data.lastName) {
      result += `${capitalize(data.lastName?.trim())} `;
    }
    if (data.firstName) {
      const firstName = data.firstName.trim().charAt(0).toUpperCase();
      result += firstName ? `${firstName}.` : firstName;
    }
    if (data.middleName) {
      const middleName = (data.middleName || '').trim().charAt(0).toUpperCase();
      result += middleName ? `${middleName}.` : middleName;
    }
  }

  return result.trim();
};

export const getFioFull = (data?: { firstName?: string; lastName?: string; middleName?: string }): string => {
  return [data?.lastName?.trim(), data?.firstName?.trim(), data?.middleName?.trim()].filter(Boolean).join(' ').trim();
};

/**
 * @deprecated Используй метод для парсинга из @asg/core
 */
export const formatNumber = (value: any): string => {
  if (value === undefined || isNaN(value)) {
    return '';
  }
  return formatNumberMoney(value);
};

export const capitalizeFio = (str: string) => {
  const mappingLetter = (letter: string, index: number) => (index === 0 ? letter.toUpperCase() : letter.toLowerCase());

  const mapping = (word: string) => {
    const mass = word.split('-');
    if (mass.length === 1) {
      return word.split('').map(mappingLetter).join('');
    } else {
      const nextMass = mass.map((itemWork) => itemWork.split('').map(mappingLetter).join(''));
      return nextMass.join('-');
    }
  };

  return str.split(' ').map(mapping).join(' ');
};

export const valueError = (valueOne: number | null, valueTwo: number | null) => {
  let value: string | number = 0;
  if (valueOne !== null && valueTwo !== null) {
    const result = valueOne - valueTwo;
    if ((result ^ 0) === result) {
      value = result;
    } else {
      value = result.toFixed(2);
    }
  } else if (valueOne !== null) {
    value = valueOne;
  }
  return value?.toString();
};

export const objectToOptions = (object: Record<string, string>) =>
  Object.entries(object)
    .map(([value, label]) => ({ value, label }))
    .filter((item) => item.label);

export const getDataApplicantFullName = (data: Record<string, any>) => {
  const isRegCard = data?.regCard !== null;
  if (isRegCard) {
    return [data?.regCard?.senderSecondName, data?.regCard?.senderFirstName, data?.regCard?.senderMiddleName]
      .filter(Boolean)
      .join(' ');
  }
  if (!isRegCard) {
    return [
      data?.extensionData?.application?.sender?.secondName,
      data?.extensionData?.application?.sender?.firstName,
      data?.extensionData?.application?.sender?.middleName,
    ]
      .filter(Boolean)
      .join(' ');
  }
  return '';
};

export const getDataPolicyHolderFullName = (data: Record<string, any>) => {
  const isRegCard = data?.regCard !== null;
  if (isRegCard && data?.policyholderFullName === null) {
    return [data?.regCard?.senderSecondName, data?.regCard?.senderFirstName, data?.regCard?.senderMiddleName]
      .filter(Boolean)
      .join(' ');
  }
  if (!isRegCard && data?.policyholderFullName === null) {
    return [
      data?.extensionData?.application?.sender?.secondName,
      data?.extensionData?.application?.sender?.firstName,
      data?.extensionData?.application?.sender?.middleName,
    ]
      .filter(Boolean)
      .join(' ');
  }
  if (data?.policyholderFullName !== null) {
    return data?.policyholderFullName;
  }
  return '';
};

export const getDataPolicyFullName = (data?: Record<string, any>) => {
  if (data?.policyId === null) {
    return getDataPolicyHolderFullName(data);
  } else {
    const policyholder = data?.policy?.policyholder;
    const fullName = [policyholder?.surname, policyholder?.name, policyholder?.middlename].filter(Boolean).join(' ');
    return fullName;
  }
};

export const getLinkAndQueryParams = (link: string, obj?: Record<string, any>) => {
  if (!obj) return link;

  const filterObj = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      return { ...acc, [key]: value };
    } else return acc;
  }, {});

  if (Object.values(filterObj).length) {
    const queryString = Object.entries(filterObj).reduce((acc, [key, value], index) => {
      if (index === 0) {
        return `${key}=${value}`;
      }
      return `${acc}&${key}=${value}`;
    }, '');

    return `${link}?${queryString}`;
  } else return link;
};

export const castKeyToObjectKey = <T extends { NO_DATA: string }>(obj: T, k?: string): keyof T =>
  k !== undefined && k in obj ? (k as keyof T) : 'NO_DATA';
