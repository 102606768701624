import { Profile } from 'modules/user-administration';
import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const getProfile = (builder: RtkBuilder) =>
  builder.query<Profile, void>({
    query: () => ({
      url: api.userAdministration.profile,
    }),
    providesTags: ['profile'],
  });
