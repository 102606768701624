export type Response<TData = any> = TData & Partial<ErrorResponse>;

export type ErrorResponse = {
  error?: string;
  status: number;
  message: string;
};

export type FetchPageData<TData> = {
  header: {
    title: string;
  };
  data: TData;
};

export type ResponseDataPagination<TData> = {
  data: TData;
  pagination: {
    offset: number;
    linit: number;
    total: number;
  };
};

export type FetchResponse<TData> = {
  loading: boolean;
  fetch: () => Promise<TData>;
  response: TData | null;
};

export type Error = {
  message: string;
};

export type Option = {
  value: any;
  label: string;
};

export type Options = (Option & {
  options?: Options;
})[];

export enum Gender {
  FEMALE = 'Женский',
  MALE = 'Мужской',
  NON = 'не определен',
}

export enum Relation {
  SON = 'Сын',
  DAUGHTER = 'Дочь',
  HUSBAND = 'Муж',
  WIFE = 'Жена',
}

export enum PaymentTypes {
  CASH = 'Наличными',
  NONCASH = 'Безналичный перевод',
  POSTAL_TRANSFER = 'Почтовый перевод',
}

export type ProductOptionsType = {
  configName: string;
  configId: string;
  products: {
    name: string;
    code: string;
    versionId: string;
    versionName: string;
  }[];
};

export type ProductOptionsWithConfigType = {
  configName: string;
  configId: string;
  productsString: string;
  productCodeWithCommonString: string;
  products: {
    name: string;
    code: string;
    versionId: string;
    versionName: string;
  }[];
};

export type ResponseErrorsType = {
  code?: string;
  message?: string;
};
