import React, { Suspense } from 'react';

import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Loader } from '@asg/core';

import { store } from 'app/providers';

const Auth = React.lazy(() => import('modules/auth/hoc/auth'));
const Routes = React.lazy(() => import('app/routes'));

const App = () => {
  return (
    <div className="App">
      <Suspense fallback={<Loader pastDelay />}>
        <Provider store={store}>
          <Switch>
            <Route
              exact
              path="/"
            >
              <Redirect to="/cabinet" />
            </Route>

            <Suspense fallback={<Loader />}>
              <Route
                path="/cabinet"
                component={Routes}
              />
              <Auth />
            </Suspense>
          </Switch>
        </Provider>
      </Suspense>
    </div>
  );
};

export default App;
