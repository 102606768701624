import React, { createContext } from 'react';

import { useHistory } from 'react-router-dom';

import { usePrevious } from '@asg/core';

// eslint-disable-next-line no-spaced-func
export const LocationContext = createContext<{
  prevPathname?: string;
}>({
  prevPathname: '/',
});

const LocationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const history = useHistory();
  const prevPathname = usePrevious(history.location.pathname);

  return (
    <LocationContext.Provider
      value={{
        prevPathname,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
