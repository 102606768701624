import { generatePath } from 'react-router-dom';

import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const resetUserPassword = (builder: RtkBuilder) =>
  builder.mutation<void, { id: number }>({
    query: ({ id }) => ({
      url: generatePath(api.userAdministration.resetUserPassword, { id }),
    }),
  });
