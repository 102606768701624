import { useFetch } from '@asg/core';
import type { Role } from 'modules/user-administration/types/roles';
import type { ResponseDataPagination } from 'types/general';

import { api } from '../api/endpoints';

export const useGetRole = (id: string) => ({
  ...useFetch(`${api.roleList}/${id}`),
});

export const useEditRole = (
  id: string,
): {
  save: (role: Role) => Promise<any>;
  loading: boolean;
} => {
  const { put, loading } = useFetch(`${api.roleList}/${id}`);

  const save = (role: Role) => put(role);

  return { save, loading };
};

export const useCreateRole = (): {
  create: (role: Role) => Promise<any>;
  loading: boolean;
} => {
  const { post, loading } = useFetch(`${api.roleList}`);

  const create = (role: Role) => post(role);

  return { create, loading };
};

export const useRemoveRole = (
  id: string,
): {
  remove: () => Promise<any>;
  loading: boolean;
} => {
  const { del, loading } = useFetch(`${api.roleList}/${id}`);

  const remove = () => del();

  return { remove, loading };
};

export const useGetRoleOptions = () => {
  return useFetch(
    `${api.roleList}?limit=100`,
    {
      onNewData: (_, nextData: ResponseDataPagination<Role[]>) =>
        nextData.data?.map((role) => ({
          value: role.name,
          label: `${role.title} ${role.isDeleted ? '(удалена)' : ''}`,
          isDeleted: role.isDeleted,
        })),
    },
    [],
  );
};
