import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const getRole = (builder: RtkBuilder) =>
  builder.query<GetRoleResponse, string>({
    query: (code) => ({
      url: api.userAdministration.roleList + `/${code}`,
    }),
  });

type GetRoleResponse = {
  isDeleted: boolean;
  name: string;
  permissionGroups: string[];
  title: string;
};
