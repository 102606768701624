import { rtk } from 'shared/api';

import { getDivision } from './getDivision';
import { getPermissionsList } from './getPermissionsList';
import { getProfile } from './getProfile';
import { getRole } from './getRole';
import { getUser } from './getUser';
import { resetUserPassword } from './resetUserPassword';
import { saveDivision } from './saveDivision';
import { saveRole } from './saveRole';
import { saveUser } from './saveUser';
import { setPassword } from './setPassword';
import { changeUserVacation, createUserVacation, deleteUserVacation } from './userVacation';

export const userAdministrationApi = rtk.injectEndpoints({
  endpoints: (build) => ({
    resetUserPassword: resetUserPassword(build),
    getProfile: getProfile(build),
    getUser: getUser(build),
    saveUser: saveUser(build),
    getRole: getRole(build),
    saveRole: saveRole(build),
    getDivision: getDivision(build),
    saveDivision: saveDivision(build),
    setPassword: setPassword(build),
    getPermissionsList: getPermissionsList(build),
    createUserVacation: createUserVacation(build),
    changeUserVacation: changeUserVacation(build),
    deleteUserVacation: deleteUserVacation(build),
  }),
});

export const {
  useSetPasswordMutation,
  useGetDivisionQuery,
  useGetProfileQuery,
  useGetRoleQuery,
  useGetUserQuery,
  useResetUserPasswordMutation,
  useSaveDivisionMutation,
  useSaveUserMutation,
  useSaveRoleMutation,
  useGetPermissionsListQuery,
  useCreateUserVacationMutation,
  useChangeUserVacationMutation,
  useDeleteUserVacationMutation,
} = userAdministrationApi;
