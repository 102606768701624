import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const createUserVacation = (builder: RtkBuilder) =>
  builder.mutation<UserVacationResponse, CreateUserVacationRequest>({
    query: ({ userId, ...body }) => ({
      url: api.userAdministration.userVacation.replace(':id', userId?.toString()),
      method: 'POST',
      body,
    }),
  });

export const changeUserVacation = (builder: RtkBuilder) =>
  builder.mutation<UserVacationResponse, ChangeUserVacationRequest>({
    query: ({ userId, ...body }) => ({
      url: api.userAdministration.userVacationChange.replace(':id', String(userId)),
      method: 'PUT',
      body,
    }),
  });

export const deleteUserVacation = (builder: RtkBuilder) =>
  builder.mutation<void, ChangeUserVacationRequest>({
    query: ({ userId }) => ({
      url: api.userAdministration.userVacationChange.replace(':id', String(userId)),
      method: 'DELETE',
    }),
  });

export type CreateUserVacationRequest = {
  userId: number;
  absenceType?: string;
  startAbsenceDate?: string;
  endAbsenceDate?: string;
  createdByUserId?: string;
};

export type UserVacationResponse = {
  absenceType: 'VACATION';
  createdByUserId: string;
  endAbsenceDate: string;
  startAbsenceDate: string;
};

export type ChangeUserVacationRequest = {
  userId: number;
  absenceType?: string;
  startAbsenceDate?: string;
  endAbsenceDate?: string;
};
