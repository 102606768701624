import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const getUser = (builder: RtkBuilder) =>
  builder.query<GetUserResponse, number>({
    query: (id) => ({
      url: api.userAdministration.userList + `/${id}`,
    }),
  });

export type GetUserResponse = {
  id: number;
  login: string;
  division?: {
    name: string;
    title: string;
    parentName?: string;
    isDeleted: false;
  };
  role: {
    name: string;
    title: string;
    permissionGroups: string[];
    isDeleted: false;
  };
  fullName: string;
  surname: string;
  name: string;
  middlename: string;
  gender: 'FEMALE' | 'MALE';
  email: string;
  internalPhoneNumber: string;
  birthday: string;
  isActive: true;
  inactiveDateTime: null;
  created: string;
};
