import { API_HOST } from 'app/configs';

const apiHost = API_HOST;

const getApiHost = (): string => apiHost;

export const api = {
  auth: {
    login: 'api/auth/login',
    logout: 'api/auth/logout',
    refreshToken: 'api/auth/refresh_token',
  },
  regCard: {
    card: 'api/reg_card',
    documentTypeDict: 'api/reg_card/dictionary/document_type',
    saveRegCard: 'api/reg_card/:reg_card',
    createApplicant: 'api/reg_card/:regCardId/applicant',
    applicant: 'api/reg_card/:regCardId/applicant/:applicantId',
    losses: 'api/reg_card/:regcardId/loss_list',
    regCardList: 'api/reg-card/grid/reg_card_view',
    regCardCancel: 'api/reg_card/:reg_card/cancel',
    returnToSed: 'api/reg_card/:id/return',
    bindApplicantToRegCard: 'api/reg_card/:lossId/applicant',
    regCardUpdateApplicant: 'api/reg_card/:regCardId/applicant/:applicantId',
    bindRegCardToLoss: 'api/reg_card/:regCardId/attach_to_loss/:lossId',
    regNotification: 'api/reg_card/:regCardId/create_object',
    unlinkAndCancel: 'api/reg_card/:id/unlink_and_cancel',
    downloadDocuments: 'api/reg_card/:id/download_documents',
    reassign: 'api/reg_card/:id/reassign',
    reassignSpecialist: 'api/reg_card/:id/reassignee',
  },
  product: {
    product: 'api/product',
    productDict: 'api/product/dict/rule',
    list: 'api/product/grid/product_data_view',
    version: 'api/product/grid/product_version_view',
    create: 'api/product',
    card: 'api/product/:id',
    productGroup: 'api/product_group',
    groups: {
      list: 'api/product_group',
      card: 'api/product_group/:id',
    },
    config: {
      list: 'api/product/grid/policy_registry_config_view',
      productList: 'api/product',
    },
  },
  userAdministration: {
    setPassword: 'api/user-administration/user/:user-id/password/new',
    profile: 'api/user-administration/me',
    userList: 'api/user-administration/user',
    roleList: 'api/user-administration/role',
    permissionsList: 'api/user-administration/permission_group',
    divisionList: 'api/user-administration/division',
    resetUserPassword: 'api/user-administration/v1/user/:id/password/reset',

    // lists
    roleListGrid: 'api/user-administration/grid/role',
    userListGrid: 'api/user-administration/grid/user',
    agentList: 'api/user-administration/agent',
    divisionListGrid: 'api/user-administration/grid/division',
    rolePermission: 'api/user-administration/permission/group',
    userVacationGrid: 'api/user-administration/user/:id/user_vacation',

    userVacationChange: 'api/user-administration/user_vacation/:id',
    userVacation: 'api/user-administration/user/:id/user_vacation',
  },

  medoblako: {
    serviceReport: 'api/medoblako/service_report',
    approveAct: 'api/medoblako/act/:id/approve',

    uploadReport: 'api/medoblako/service_report',
    spreadCosts: 'api/medoblako/act/:id/approve',
    report: 'api/medoblako/service_report/:id',
    processing: 'api/medoblako/service_report/:id/processing_state',
    summaryReport: 'api/medoblako/summary_report',
    reject: 'api/service-partner/medoblako/act/:id/reject',
    revoke: 'api/service-partner/medoblako/act/:id/revoke',
    send: 'api/service-partner/medoblako/act/:id/send_to_payoff',

    partners: 'api/medoblako/grid/registry_view',
    reports: 'api/medoblako/grid/report_view',
    reportsRow: 'api/medoblako/grid/report_row_view',
  },

  clients: {
    individual: {
      list: 'api/contragent-individual/grid/contragent_individual_data_view',
      card: 'api/contragent-individual',
      clientsPolicies: 'api/contragent-individual/:id/policy',
      saveClientData: 'api/contragent-individual/:id/update_main_data',
      contact: 'api/contragent-individual/:contragentId/contact',
      log: {
        list: 'api/contragent-individual/:id/log',
        item: 'api/contragent-individual/log/:id',
      },
      deleteContacts: 'api/contragent-individual/:contragentId/contacts',
    },
    entity: {
      list: 'api/contragent-legal/grid/contragent_legal_data_view',
      card: 'api/contragent-legal',
      saveClientData: 'api/contragent-legal/:id/update_main_data',
      contact: 'api/contragent-legal/:contragentId/contact',
      log: {
        list: 'api/contragent-legal/:id/log',
        item: 'api/contragent-legal/log/:id',
      },
      deleteContacts: 'api/contragent-legal/:contragentId/contacts',
    },
    policyHolder: {
      url: 'api/client/policyholder/policy',
      query: 'api/findByPolicyholderId=',
    },
    policyInsured: {
      url: 'api/client/insured/policy',
      query: 'api/findByInsuredId=',
    },
    policyBeneficiary: {
      url: 'api/client/beneficiary/loss',
      query: 'api/findByBeneficiaryId=',
    },
  },
  policyExpense: {
    grid: 'api/policy-expense/commission_forecast/report',
    card: 'api/policy-expense/commission_forecast/report/:id',
    create: 'api/policy-expense/commission_forecast/report',
    sendToActuarial: 'api/policy-expense/commission_forecast/report/:id/send_to_actuarial',
    sendToAccounting: 'api/policy-expense/commission_forecast/report/:id/send_to_accounting',
    reverse: 'api/policy-expense/commission_forecast/report/:id/reverse',
    externalBusinessOperation: 'api/policy-expense/external_business_operation/',
    businessOperation: 'api/policy-expense/business_operation/:operationId/registry/:registryId/distribute',
    act: 'api/policy-expense/act/:act_id',
    acts: 'api/policy-expense/grid/acts_view',
    approve: 'api/policy-expense/act/:act_id/approve',
    createAct: 'api/policy-expense/act',
    partner: 'api/policy-expense/partner',
    partnerAgent: 'api/policy-expense/partner/:partner_id/agent',
    policyExpensesView: 'api/policy-expense/grid/policy_expenses_view',
    refundsView: 'api/policy-expense/grid/policy_expense_refunds_view',
    sendToPayoff: 'api/policy-expense/act/:act_id/send_to_payoff',
    cancel: 'api/policy-expense/act/:act_id/cancel',
    requisites: 'api/policy-expense/act/:id/agent/requisites',
    revoke: 'api/policy-expense/act/:id/revoke',
    refund: 'api/policy-expense/external_business_operation_refund/',
    distribute: 'api/policy-expense/business_operation/:operationId/registry/:registryId/distribute',
    printFormAct: 'api/policy-expense/act/:id/print_form',
  },
  policy: {
    calculate_pdp: 'api/policy/calculate_pdp',
    calculate_pdp_letter: 'api/policy/calculate_pdp_letter',
    printForm: 'api/policy/:id/print_form',
    list: 'api/policy/grid/policy_data_view',
    listPopup: 'api/policy/grid/individual_policy_data_view',
    list_insis: 'api/insis_policy',
    insis: 'api/insis_policy/:id',
    main: 'api/policy/:id/main',
    loss: 'api/policy/:id/loss',
    docs: 'api/policy/:id/document',
    delete_docs: 'api/policy/policy_document/:id',
    acquisition: 'api/policy/:id/acquisition',
    addendum: 'api/policy/:id/addendum',
    insured: 'api/policy/policy/:id/insured',
    terminations: 'api/policy/:id/terminations',
    terminationList: 'api/policy/:id/termination',
    saveAddendum: 'api/policy/:policyId/insured/:insuredId',
    service: 'api/policy/:id/service',
    log: {
      list: 'api/policy/:id/log',
      item: 'api/policy/log/:id',
    },
    contract: {
      list: 'api/policy/contract',
      create: 'api/policy/contract',
      item: 'api/policy/contract/:id?withProducts=true',
    },
  },
  insisLoss: {
    list_insis: 'api/insis-loss/policy',
    insis: 'api/insis-loss/policy/:id',
  },
  insisTermination: {
    terminationInsis: 'api/insis-termination/policy/:id',
    list_insis: 'api/insis-termination/policy',
  },
  policyRegistry: {
    createFromBufferList: 'api/policy-registry/create_policy/my',
    processing: 'api/policy-registry/:id/processing_state',
    list: 'api/policy-registry/grid/policy_registry_front_view', // получить список реестров полисов
    card: 'api/policy-registry/:id', //  получить информацию по реестру полисов
    configurationsList: 'api/policy-registry/parsing_config', // списка конфигураций парсинга
    registryListRows: 'api/policy-registry/grid/policy_registry_row_front_view', // получить информацию по строкам реестра полисов
    registryPolicyBufferListApprovedRows: 'api/policy-registry/grid/policy_registry_row_approve_front_view', // Согласованные записи в буфере полисов
    registryListApprovedRows: 'api/policy-registry/approved_rows', // получить согласованные записи
    registryRow: 'api/policy-registry/:registryId/row/:rowId', // получить информацию по строке реестра
    registryRowLog: 'api/policy-registry/:registryId/row/:rowId/log', // получить логи по строке реестра
    registryAllListRows: 'api/policy-registry/grid/policy_registry/row', // получить информацию по строкам всех реестров, назначенным на сотрудника (для Андеррайтера)
    uploadRegistry: 'api/policy-registry', // создать реестр полисов
    uploadRenewalsRegistry: 'api/policy-registry/:id', // загрузка обновления реестра из карточки реестра
    processRow: 'api/policy-registry/row/process', // обработка событий строк
    createPolicies: 'api/policy-registry/:id/create_policy', // создать полисы
    deletePolicy: 'api/policy-registry/:id',
    payoffPolicy: 'api/policy_registry/:id/payoff',
    policyRegistryProducts: 'api/policy-registry/products',
    createAct: 'api/policy-registry/:id/act',
    fixContragent: 'api/policy-registry/:registryId/row/:rowId/fix_contragent',
    config: {
      list: 'api/registry_config',
      card: 'api/registry_config/:id',
    },
    postPolicyRegistryInvoice: 'api/policy-registry/:registryId/invoice', // создание и отправка счета на почту
    bindPaymentToRegisterIndividualStructure: 'api/incoming_payment/registry/:regId',
  },
  reportExel: '/api/report/excel',

  loss: {
    accidentTypeDict: '/api/loss/dictionary/accident_type',
    accidentFormatDict: '/api/loss/dictionary/accident_format',
    reportGridRequest: '/api/loss/grid/loss_excel_report_view',
    list: 'api/loss',
    duplicate: 'api/loss/:lossId/:type/:typeId/copy',
    rejects_and_requests: 'api/loss/grid/reject_and_request_view',
    evaluation: {
      preEval: 'api/loss/loss_pre_evaluation',
      lastEval: 'api/loss/:id/loss_last_evaluation',
      create: 'api/loss/:id/loss_evaluation',
      list: 'api/loss/:id/loss_evaluation',
    },
    payoff: {
      list: 'api/loss/:id/loss_payoff',
      create: 'api/loss/:id/loss_payoff',
      card: 'api/loss/loss_payoff/:id',
      approve: 'api/loss/:lossId/send_to_payoff',
    },
    request: {
      list: 'api/loss/:lossId/loss_request',
      create: 'api/loss/:lossId/loss_request',
      card: 'api/loss/loss_request/:requestId',
      cancelSending: 'api/loss/loss_request/:id/cancel_sending',
      getLabel: 'api/loss/loss_request/:id/get_label/:labelName',
    },
    refuse: {
      card: 'api/loss/loss_reject/:id',
      create: 'api/loss/:lossId/loss_reject',
      save: 'api/loss/loss_reject/:reject_id',
      send: 'api/loss/loss_reject/:reject_id/send',
      cancelSending: 'api/loss/loss_reject/:id/cancel_sending',
      getLabel: 'api/loss/loss_reject/:id/get_label/:labelName',
    },
    reject: {
      list: 'api/loss/:lossId/loss_reject',
    },
    documents: {
      remove: 'api/loss/loss_document/:documentId',
    },
    register: {
      possiblePoliciesAndLosses: 'api/loss/:lossId/insured_policies_losses',
    },

    lossesList: 'api/loss/grid/loss_data_view',
    lossRejectAndRequest: 'api/loss/grid/reject_and_request_view',
    tasksList: 'api/loss/grid/loss_tasks_view',
    lossAddDocument: 'api/loss/:lossId/loss_document',
    returnToTheSpecialist: 'api/loss/:lossId/send_to_specialist',
    forwardToTheSpecialist: 'api/loss/:lossId/send_to_specialist/:specialistId',
    returnToTheExpert: 'api/loss/:lossId/send_to_expert',
    reserveLoss: 'api/loss/:lossId/reserve/:expertId',
    closeWithoutReview: 'api/loss/:lossId/close_task',
    deleteLoss: 'api/loss/:lossId/delete',
    bindApplicantToLoss: 'api/loss/:lossId/applicant',
    policyDataFromLoss: 'api/loss/:id/policy/:policyId',
    unbindApplicantFromoLossCard: 'api/loss/:lossId/applicant/:applicantId',
    regCardLossListOnPolicy: 'api/loss/:lossId/policy/:policyId/loss',
    regCardPotentialPolicyListOnInsured: 'api/loss/:lossId/policy/:policyId/policy',
    evaluationListOnLoss: 'api/loss/:lossId/loss_evaluation',
  },

  termination: {
    returnManual: 'api/termination/:id/return_canceled_status',
    saveComment: 'api/termination/:id/save_comment',
    reportMisinformationView: 'api/termination/grid/report_misinformation_view',
    policyForMisinformation: 'api/termination/grid/policy_for_misinformation_view',
    terminationActView: 'api/termination/grid/misinformation_termination_act_view',
    misinformationRegCardView: 'api/termination/grid/misinformation_reg_card_view',
    misinformationExcelReportView: 'api/termination/grid/misinformation_excel_report_view',
    pdpExcelReportView: 'api/termination/grid/pdp_excel_report_view',
    pdpRegCardView: 'api/termination/grid/pdp_reg_card_view',
    pdpRegCardObjectView: 'api/termination/grid/pdp_reg_card_object_view',
    pdpTerminationActsView: 'api/termination/grid/pdp_termination_act_view',
    policyForPdpView: 'api/termination/grid/policy_for_pdp_view',
    pdpView: 'api/termination/grid/pdp_view',
    rnvErrorsView: 'api/termination/grid/termination_financial_operation_error_view',
    rnvIndividualErrorsView: 'api/termination/individual/grid/termination_financial_operation_error_view',

    reg_card: {
      otherPDPRegCard: 'api/termination/grid/pdp_reg_card_view',
      correctInSed: 'api/termination/reg_card/:reg_card_id',
      returnToSed: 'api/termination/reg_card/:reg_card_id/return',
      registerApplication: 'api/termination/create_termination_from_reg_card/:reg_card_id',
    },

    reports: {
      report: 'api/termination/report',
      list: 'api/termination/grid/report_pdp_view',
      card: 'api/termination/report/:id',
      reorder: 'api/termination/report/:id',
      approve: 'api/termination/report/:id/confirm',
      restart: 'api/termination/report/:id/restart_print_form_generation',
    },
    operuExcelReportView: 'api/termination/grid/operu_excel_report_view',

    terminationRequest: 'api/termination/:terminationId/termination_request/:id',
    terminationDeleteRequest: 'api/termination/termination_request/:id',
    setting: 'api/termination/:id/setting',
    data: 'api/termination',
    revoke: 'api/termination/:id/revoke',
    restore: 'api/termination/:id/restore',
    revokeAndRestore: 'api/termination/:id/revoke_and_restore',
    toInProcess: 'api/termination/:id/to_in_process',
    toDraft: 'api/termination/:id/to_draft',
    toBeDetermined: 'api/toBeDetermined',
    addTax: 'api/termination/:termination_id/tax_certificate',
    updateTax: 'api/termination/:termination_id/tax_certificate/:certificate_id',
    setLetterThroughAnotherDepartment: 'api/termination/:id/sent_letter_through_another_department',
    userManage: 'api/termination/grid/user_management_view',
    calculateSum: 'api/termination/calculate_sum',
    edit: 'api/termination/:id/edit',
    excelReport: 'api/termination/grid/cooloff_excel_report_view',
    excelRequest: '/api/report/excel',
    otherCoolOff: 'api/termination?findByPolicyNumber=:policyNumber&terminationType=COOLOFF',
    cashPaymentNotify: 'api/termination/:id/notification_about_cash_payment',
    reassign: 'api/termination/:id/reassign',
    list: 'api/termination/grid/cooloff_reg_card_view',
    listSedInInsis: 'api/termination/grid/cooloff_reg_card_object_view',
    statement: 'api/termination/:id',
    regCart: 'api/termination/create_termination_from_reg_card/:id',
    createAct: 'api/termination/act',
    correctRegCart: 'api/termination/reg_card/:reg_card_id',
    returnRegCart: 'api/termination/reg_card/:reg_card_id/return',
    policy: 'api/termination/policy/:policyId',
    createLetters: 'api/termination/letter_report',
    terminationRequests: 'api/termination/:terminationId/requests',
    letterReports: 'api/termination/report',
    viewed: 'api/termination/:terminationId/task/:groupId/viewed',
    processing: {
      acts: 'api/termination/act/processing_state',
    },
    grid: {
      cooloff_reg_card_view: 'api/termination/grid/cooloff_reg_card_view',
      pdp_reg_card_view: 'api/termination/grid/pdp_reg_card_view',
    },

    unloading: 'api/termination/grid/cooloff_excel_suspect_report_view',
    manageUserId: 'api/termination/manage/user/:id',
    paymentOrder: 'api/integration/payment_order/:parusOrderReference/test',
    documentRemove: 'api/termination/document/:terminationId',
    addDocument: 'api/termination/:terminationId/document',
    termination: {
      setting: 'api/termination/:id/setting',
      returnManual: 'api/termination/:id/return_canceled_status',
      bankReport: 'api/termination/grid/report_cooloff_view',
      bankReportCard: 'api/termination/report/:id',
      bankReportUpdate: 'api/termination/report/:id',
      bankReportApprove: 'api/termination/report/:id/confirm',
      mistake: 'api/termination/:terminationId/mistake',
      termination_request: 'api/termination/:terminationId/termination_request',
      duplicate: 'api/termination/:terminationId/duplicate',
      canceled_by_policyholder: 'api/termination/:terminationId/canceled_by_policyholder',
      canceled_by_bank: 'api/termination/:terminationId/canceled_by_bank',
      cooloffLetters: 'api/termination/grid/cooloff_error_and_reject_view',
      policyForCooloff: 'api/termination/grid/policy_for_cooloff_view',
      lettersReport: 'api/termination/grid/report_cooloff_view',
      letterReportCard: 'api/termination/report/:id',
      letterReportReorder: 'api/termination/letter_report/:id',
      letterReportApprove: 'api/termination/letter_report/:id/confirm',
      unbindTerminationFromAct: 'api/termination/:terminationId/reset_act',
      restartPrintFormGeneration: 'api/termination/report/:id/restart_print_form_generation',
      setLetterThroughAnotherDepartment: 'api/termination/:id/sent_letter_through_another_department',
    },
    acts: {
      list: 'api/termination/grid/cooloff_termination_act_view',
      card: {
        info: 'api/termination/act/',
        rows: 'api/termination/grid/act_row_view',
        grid: 'api/termination/grid/termination_view',
        cooloffView: 'api/termination/grid/cooloff_view',
        misinformationView: 'api/termination/grid/misinformation_view',
      },
    },
  },
  payment: {
    incoming: 'api/incoming_payment/',
    outgoing: 'api/outgoing_payment/',
    incomingPaymentBindToRegistry: 'api/incoming_payment/:id/policy_registry',
    incomingPaymentBindToPolicies: 'api/incoming_payment/:id/policy_registry/policy',

    joinIncomingToRegistry: 'api/incoming_payment/:id/registry/:registryId', // Привязать входящийп латеж к реестру
  },

  service: {
    partners: 'api/service_partner/',
    createReport: '/api/report/excel',
    getReports: {
      url: '/api/report',
    },
    createReportWithProductVersion: '/api/report/product_version/:productVersion/excel',
  },

  registration: {
    list: 'ENDPOINT_IS_NOT_USED',
  },
  terminationPolicy: {
    untieTerminationPolicy: 'api/agent-request-termination/file/:id/terminate',
    untieLetterTerminationPolicy: 'api/agent-request-termination/letter/:id/terminate',
    connectedFilesList: 'api/agent-request-termination/grid/file_view',
    filesList: 'api/agent-request-termination/grid/last_file_view',
    filePoliciesList: 'api/agent-request-termination/grid/registry_view',
    filePoliciesListLetters: 'api/agent-request-termination/grid/letter_view',
    file: 'api/agent-request-termination/file/:id',
    uploadFile: 'api/agent-request-termination/registry',
    registryCardPolicies: 'api/agent-request-termination/grid/row_view',
    registryCard: 'api/agent-request-termination/registry/:id',
    terminatePolicy: 'api/agent-request-termination/terminate',
    terminateLetter: 'api/agent-request-termination/row/:id',
    letters: {
      list: 'api/agent-request-termination/grid/letter_view',
      card: 'api/agent-request-termination/letter/:id',
      cardRegistry: 'api/agent-request-termination/grid/row_view',
    },
  },
  servicePartner: {
    registry: 'api/service-partner/call-center/registry',
    registryGrid: 'api/service-partner/call-center/grid/registry_view',
    registryGridRow: 'api/service-partner/call-center/grid/row_view',
    registryCard: 'api/service-partner/call-center/registry/:id',
    registryCardProcess: 'api/service-partner/call-center/registry/:id/process',
    registryCardReject: 'api/service-partner/call-center/registry/:id/reject',

    reject: 'api/service-partner/medoblako/act/:id/reject',
    revoke: 'api/service-partner/medoblako/act/:id/revoke',
    send: 'api/service-partner/medoblako/act/:id/send_to_payoff',

    servicePartnerAct: 'api/service-partner/medoblako/act/:id',
    servicePartnerActGrid: 'api/service-partner/medoblako/grid/registry_insured_act_view',
    servicePartnerActRefund: 'api/service-partner/medoblako/act/:id/refund',
    servicePartnerActExpense: 'api/service-partner/medoblako/act/:id/expense',
    servicePartnerReport: 'api/service-partner/medoblako/act',
    serviceProgram: 'api/service-partner/medoblako/service_program',
  },
  individualTermination: {
    registryGrid: 'api/individual-termination/grid/registry_view',
    registerCard: 'api/individual-termination/registry/:id',
    registerCardGrid: 'api/individual-termination/grid/row_view/:id',
    policyCardGrid: 'api/individual-termination/grid/row_view',
    terminationCard: 'api/termination/individual/:id',
    terminationCardGrid: 'api/termination/individual/grid/termination_view',
    terminationCreate: 'api/termination/individual',
    terminationCardSaveCancel: 'api/termination/individual/:id/to_in_process',
    policy: 'api/termination/policy',
    cancel: 'api/termination/individual/:id/cancel',
    cancelToDraft: 'api/termination/individual/:id/canceled_to_draft',
    addDocs: 'api/termination/individual/:id/document',
    removeDocs: 'api/termination/individual/document/:id',
    saveComment: 'api/termination/individual/:id/comment',
    terminationActGrid: 'api/termination/individual/grid/termination_act_view',
    act: 'api/termination/individual/act/:id',
    terminationForActGrid: 'api/termination/individual/grid/termination_for_act_view',
    postAct: 'api/termination/individual/act',
    actGrid: 'api/termination/individual/grid/act_row_view',

    dataError: 'api/termination/individual/:id/to_data_errors',
    canceledToDraft: 'api/termination/individual/:id/canceled_to_draft',

    otherIndividualTermination: 'api/termination/individual',
  },
  rko: {
    businessOperation: 'api/business_operation',
    businessOperationCorrection: 'api/business_operation/:operationId/policy/:policyId/correction',
    businessOperationCorrectionDelete: 'api/business_operation/:operationId/correction/:correctionId',
    businessOperationCard: 'api/business_operation/:id',
  },
  integrations: {
    accountingEntry: 'api/integration/accounting_entry',
    paymentOrderTest: 'api/integration/payment_order/:parusOrderId/test',
  },
};

export default getApiHost;
