import { api } from 'modules/user-administration/api/endpoints';
import { getHashPassword } from 'services/auth/utils';
import { RtkBuilder } from 'shared/api/rtk';

export const setPassword = (builder: RtkBuilder) =>
  builder.mutation<void, { userId: string | number; confirmPassword: string; password: string }>({
    query: ({ password, confirmPassword, userId }) => ({
      url: api.setPassword.replace(':user-id', userId?.toString()),
      method: 'POST',
      credentials: 'include',
      body: {
        passwordHash: getHashPassword(password),
        passwordHashConfirm: getHashPassword(confirmPassword),
      },
    }),
  });
