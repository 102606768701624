import { momentDate, formatNumberMoney } from '@asg/core';
import type { ProductOptionsType } from 'types/general';
import { Option } from 'types/general';
import type { Loss } from 'types/loss';
import { payemntSpreadingStatus } from 'utils/dictionaries';

import {
  periods,
  policyStatuses,
  policyStatusesColor,
  policySubStatuses,
  policySubStatusesColor,
  reservedSystems,
} from '../dictionaries';
import { paymentPeriods } from '../dictionaries/common';

export * from './common';

export const getGender = (gender?: 'MALE' | 'FEMALE'): string => {
  let result = '';
  if (gender === 'MALE') {
    result = 'Мужской';
  } else if (gender === 'FEMALE') {
    result = 'Женский';
  }

  return result;
};

export const downloadFile = (url: string): void => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', 'filename');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const replaceId = (url: string, id?: string | number): string => url.replace(':id', `${id}`);

export const dictToOptions = (
  data: Record<string, string>,
): {
  label: string;
  value: string;
}[] =>
  Object.entries(data)
    .map(([value, label]) => ({
      label,
      value,
    }))
    .filter((i) => i.label);

export const getPaymentStatus = (balance = 0, sum = 0, status = ''): string => {
  if (status === 'NOT_SPREAD') {
    return 'Не разнесен';
  }
  if (balance > 0 && balance < sum) {
    return 'Частично разнесен';
  }
  if (balance === 0) {
    return 'Полностью разнесен';
  }
  return '0';
};

export const getSpreadAmount = (balance = 0, sum = 0, status = ''): string | number => {
  if (status === 'NOT_SPREAD') {
    return '0';
  }
  if (status === 'SPREAD') {
    return sum;
  }
  if (status === 'SPREAD_PARTLY') {
    return String((sum - balance).toFixed(2));
  }
  return '';
};

export const getUnspreadAmount = (balance = 0, sum = 0, status = ''): string | number => {
  if (status === 'NOT_SPREAD') {
    return sum;
  }
  if (status === 'SPREAD') {
    return '0';
  }
  if (status === 'SPREAD_PARTLY') {
    return balance;
  }
  return '';
};

export const approveStatus = (status: boolean | null): string => {
  if (typeof status === 'object' && !status) {
    return '';
  }
  return status ? 'Согласовано' : 'Не согласовано';
};

export const joinString = (str?: string | string[], separator = '; '): string => [str || []].flat().join(separator);

export const getSystemReserved = (key = 'FREE') => reservedSystems[key];

export const getPaymentSpreadStatus = (status = ''): string => {
  const normaliseStatus = status.toUpperCase();
  const returnValue = payemntSpreadingStatus[normaliseStatus] || '';
  return returnValue;
};

export const getRawPaymentSpreadStatus = (status = ''): string => {
  const normaliseStatus = status.toUpperCase();
  return normaliseStatus;
};

export const getSpreadPaymentStatusTwo = (sum: number, spreadSum: number): string => {
  const delta = sum - spreadSum;
  if (delta > 0 && spreadSum > 0) {
    return payemntSpreadingStatus.SPREAD_PARTLY;
  }
  if (delta > 0 && spreadSum === 0) {
    return payemntSpreadingStatus.NOT_SPREAD;
  }
  if (delta === 0) {
    return payemntSpreadingStatus.SPREAD;
  }
  return '';
};

export const getPaymentPeriod = (key: string): string => paymentPeriods[key];

export const getPeriod = (key: string): string => periods[key];

export const getPolicyStatusColor = (status = 'ACTIVE') => policyStatusesColor[status];

export const getPolicyStatus = (status = 'NONE') => policyStatuses[status] || '';

export const getPolicySubStatusColor = (status = 'NONE') => policySubStatusesColor[status];

export const getPolicySubStatus = (status: string) => (status ? policySubStatuses[status] : '-');

export const accidentBetweenPolicyStartAndEndDates = (
  accidentDate: string,
  policyStartDate: string,
  policyEndDate: string,
): boolean => {
  const date = new Date(accidentDate);
  const startDate = new Date(momentDate(policyStartDate).formatRaw() || '');
  const endDate = new Date(momentDate(policyEndDate).formatRaw() || '');
  endDate.setHours(23, 59, 59);
  return date >= startDate && date <= endDate;
};

export const getOption = (options: Option[], value: string | number) => {
  return options.find((option) => option.value === value);
};

export const getOptionByLabel = (options: Option[], label: string) => {
  return options.find((option) => option.label === label);
};

export const getOptionLabel = (options: Option[], value: string | number) => {
  return getOption(options, value)?.label || '';
};

export const getRiskId = (loss: Loss) => {
  const { riskCode = null, coverCode = null } = loss;
  if (riskCode && coverCode) {
    const currentCover = loss.policy?.covers?.find((c) => c.code === loss.coverCode);
    if (currentCover && currentCover.risks) {
      const currentRiskId = currentCover?.risks?.find((r) => r.code === loss.riskCode)?.id;
      if (currentRiskId) {
        return currentRiskId;
      }
    }
  }
  return null;
};

export const getFullFio = (names?: (string | undefined)[]) => {
  return names ? names.filter(Boolean).join(' ') : '';
};

export const getProductVersions = (data: ProductOptionsType[]) => {
  const items = data.map((item) => {
    const products = item.products;
    const productsStr = products.map((product) => `${product.code} (v.${product.versionId})`).join(', ');
    const productCodes = products.map((i) => `${i.code}`);
    const productCodePattern = productCodes[0].split('').map(() => '%');
    const productCodeWithCommonString = productCodePattern
      .map((char, index) => {
        const currentChar = productCodes[0][index];
        const ifCharExistInEveryProductCode = productCodes.every((i) => i[index] === currentChar);
        if (ifCharExistInEveryProductCode) return currentChar;
        return char;
      })
      .join('');
    return {
      ...item,
      productsString: productsStr,
      productCodeWithCommonString,
    };
  });

  return items;
};

export const join = (args: any[], separator: string) => args.filter(Boolean).join(separator);

export const declension = (val: number, forms: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]];
};

export const isEmptyObject = (obj: any) => Object(obj) === obj && Object.keys(obj).length == 0;

export const clearEmptyObjects: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => clearEmptyObjects(item));
  }
  if (Object(obj) === obj) {
    return Object.fromEntries(
      Object.entries(obj).flatMap(([k, v]) => {
        const val = clearEmptyObjects(v) as any;
        return isEmptyObject(val) ? [] : [[k, val]];
      }),
    );
  }
  return obj;
};
/**
 * @deprecated Используй метод для парсинга из @asg/core
 */
export const formatNumber = (value: any): string => {
  if (value === undefined || isNaN(value)) {
    return '';
  }
  return formatNumberMoney(value);
};

export const capacityNumber = (number: string, capacity = 4) => {
  if (number.length <= capacity) {
    return number;
  }

  const parts = [];
  for (let i = number.length; i > 0; i -= capacity) {
    parts.unshift(number.substring(Math.max(0, i - capacity), i));
  }

  return parts.join(' ');
};

export const objectToOptions = (object: Record<string, string>) =>
  Object.entries(object)
    .map(([value, label]) => ({ value, label }))
    .filter((item) => item.label);
