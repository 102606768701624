import { useHistory, useLocation } from 'react-router-dom';

import { useFetch, CachePolicies, message } from '@asg/core';
import generatedLinks from 'configs/routes-static';
import qs from 'qs';
import { useLoginMutation } from 'services/auth';
import { ApiError } from 'shared/api/rtk';
import type { ErrorResponse } from 'types/general';

import getApiHost, { api } from '../get-api-host';

type UseRefreshTokenResponse = {
  refresh: () => Promise<any | ErrorResponse>;
  loading: boolean;
};

export const useLogin = () => {
  const [post, { data, isLoading, error }] = useLoginMutation();

  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const loginPost = (password: string, login: string, captchaToken?: string) =>
    post({
      login,
      password,
      captchaToken,
    })
      .unwrap()
      .then((response) => {
        history.push((search.redirectUrl as string) || generatedLinks.cabinet.url);
        return response;
      })
      .catch((error: ApiError) => message.error(error.message));

  return {
    loading: isLoading,
    login: loginPost,
    data,
    error,
  };
};

export const useRefreshToken = (): UseRefreshTokenResponse => {
  const { post, loading } = useFetch(`${getApiHost()}/${api.auth.refreshToken}`, {
    credentials: 'include',
    cachePolicy: CachePolicies.NO_CACHE,
    showError: false,
  });

  return {
    loading,
    refresh: post,
  };
};
