import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

import { GetUserResponse } from './getUser';

export const saveUser = (builder: RtkBuilder) =>
  builder.mutation<GetUserResponse, Partial<SaveUserBody>>({
    query: (body) => ({
      url: api.userAdministration.userList + (body.id ? `/${body.id}` : ''),
      method: body.id ? 'PUT' : 'POST',
      body: body,
    }),
  });

export type SaveUserBody = {
  id?: number;
  login: string;
  division?: {
    name: string;
    title: string;
    parentName?: string;
    isDeleted: false;
  };
  fullName: string;
  surname: string;
  name: string;
  middlename: string;
  gender: 'FEMALE' | 'MALE';
  email: string;
  internalPhoneNumber: string;
  birthday: string;
  isActive: true;
  inactiveDateTime: null;
  created: string;
  roleName: string;
  divisionName: string;
};
