import { api } from 'services/get-api-host';
import { RtkBuilder } from 'shared/api/rtk';

export const getPermissionsList = (builder: RtkBuilder) =>
  builder.query<GetPermissionsListResponse, void>({
    query: () => ({
      url: api.userAdministration.permissionsList,
    }),
  });

type GetPermissionsListResponse = {
  permissionGroups: { name: string; title: string }[];
};
