import sha512 from 'js-sha512';

function hexToBase64(hexstring: string) {
  return window.btoa(
    hexstring
      .match(/\w{2}/g)
      ?.map((a) => String.fromCharCode(parseInt(a, 16)))
      .join('') || '',
  );
}

export function getHashPassword(password: string) {
  const hash = sha512.sha512;
  const hexPassword = hash.create().update(password).hex();
  return hexToBase64(hexPassword);
}
