import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Provider, Options, CachePolicies } from '@asg/core';
import { Mutex } from 'async-mutex';
import generatedLinks from 'configs/routes-static';
import RootProvider from 'contexts/root';
import getApiHost from 'services/get-api-host';
import { useRefreshToken } from 'services/http/auth';

const mutex = new Mutex();

export const ApiProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { refresh } = useRefreshToken();

  const options: Partial<Options> = {
    cachePolicy: CachePolicies.NO_CACHE,
    retries: 1,
    credentials: 'include',

    retryOn: async ({ response }) => {
      if (response?.status === 401) {
        await mutex.waitForUnlock();
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          await refresh().then((res) => {
            if (!res) {
              history.push(generatedLinks.auth.login.url + `?redirectUrl=${location.pathname}`);
            } else {
              release();
            }
          });
          return true;
        }
      } else if (mutex.isLocked()) {
        mutex.release();
      }
      return false;
    },
  };

  return (
    <Provider
      options={options}
      url={getApiHost()}
    >
      {children}
    </Provider>
  );
};
