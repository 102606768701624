import React, { useMemo } from 'react';

import { TableGridAsync, LayoutContent, LayoutBase } from '@asg/core';
import routerConfig from 'configs/routes-static';
import { useListContext } from 'contexts/lists';
import { api } from 'modules/user-administration/api/endpoints';
import { useGetDivision } from 'modules/user-administration/hooks/divisions';
import { isDeletedStatus } from 'utils/dictionaries/roles';
import { objectToOptions } from 'utils/functions/common';

export type DivisionRolesProps = {
  id: string;
};

const optionsIdDeletedStatus = objectToOptions(isDeletedStatus);

const DivisionRoles: React.FC<DivisionRolesProps> = ({ id }) => {
  const { permissionsGroup } = useListContext();
  const { data } = useGetDivision(id);

  const permissions = useMemo(
    () =>
      permissionsGroup?.reduce((dict, permission) => {
        dict[permission.value] = permission.label;
        return dict;
      }, {} as Record<string, string>),
    [permissionsGroup],
  );

  return (
    <LayoutBase>
      <LayoutContent title="Роли подразделения">
        <TableGridAsync
          allowRequest={!!data}
          numerable
          query={`division=LIKE(${data?.name})`}
          type="standard"
          endpoint={api.roleList}
          name={api.roleList}
          className="role-table"
          headers={[
            {
              title: 'Наименование роли',
              value: 'name',
              filter: {
                type: 'simple',
                path: 'title',
              },
              sortable: true,
              width: 200,
              render: (_, row) => (row.isDeleted ? `${row.title as string} (удалена)` : row.title),
            },
            {
              title: 'Функциональные возможности',
              value: 'permissionGroups',
              sortable: true,
              render: (value) => value?.map((v: string) => permissions[v] ?? v).join(', ') ?? '',
              width: 400,
            },
            {
              title: 'Статус',
              value: 'isDeleted',
              filter: {
                type: 'selector',
                selectFilter: {
                  options: optionsIdDeletedStatus,
                },
              },
              sortable: true,
              render: (value) => isDeletedStatus[value],
            },
          ]}
          getLink={(row) => routerConfig.cabinet.roles.role.url.replace(':id', row.name)}
        />
      </LayoutContent>
    </LayoutBase>
  );
};

export default DivisionRoles;
